import { NgModule } from '@angular/core';
import { MomentOrdinalDatePipe } from './moment-ordinal-date.pipe';

@NgModule({
    declarations: [
        MomentOrdinalDatePipe
    ],
    imports: [],
    exports: [
        MomentOrdinalDatePipe
    ]
})
export class MomentOrdinalDateModule { }
