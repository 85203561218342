import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { PatientPortalComponent } from './patient-portal/patient-portal.component';
import { ErrorComponent } from './error/error.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PatientPortalRoutingModule } from './patient-portal-routing.module';

@NgModule({
    declarations: [LoginComponent, PatientPortalComponent, ErrorComponent],
    imports: [
        CommonModule,
        PatientPortalRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        MatRadioModule,
        MatProgressBarModule,
        FontAwesomeModule
    ],
})
export class PatientPortalModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(far);
        library.addIconPacks(fas);
    }
}
