import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecureEmailComponent } from './secure-email.component';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [SecureEmailComponent],
  exports: [SecureEmailComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    MatProgressBarModule
  ]
})
export class SecureEmailModule { }
