import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthStoreActions, AuthStoreSelectors, RootStoreState } from 'src/app/root-store';
import { AuthenticationStoreEffectsService } from 'src/app/root-store/auth-store/effects';
import { AuthenticateTokenModel } from '../../shared/services/api.service';

@Component({
    selector: 'authenticator',
    templateUrl: './authenticator.component.html',
    styleUrls: ['./authenticator.component.css']
})
export class AuthenticatorComponent implements OnInit, OnDestroy {
    private _destroy$: Subject<boolean> = new Subject<boolean>();
    isWorking$: Observable<boolean> = this._store$.select(AuthStoreSelectors.selectIsLoading);
    isLoading: boolean = false;
    maskedTenantKey: string;

    constructor(
        private _router: Router,
        private _store$: Store<RootStoreState.State>,
        private _snackbar: MatSnackBar,
        private _authStoreEffects: AuthenticationStoreEffectsService
    ) {
        //Start listening for login results
        this._authStoreEffects.authenticateRequestEffect$.pipe(takeUntil(this._destroy$)).subscribe((action) => {
            if (action.type == AuthStoreActions.AuthenticateSuccess.type)
                this._router.navigate(['/dashboard']);
            else if (action.type == AuthStoreActions.AuthenticateFailure.type)
                this.invalidUrl();
        });

        let url = this._router.url;
        this.maskedTenantKey = url.replace(/\//g, '');
    }

    ngOnInit() {
        this.signin();
    }

    ngOnDestroy() {
        this._destroy$.next(true);
    }

    signin() {
        this.isLoading = true;
        if (this.maskedTenantKey) {
            const authModel = new AuthenticateTokenModel();
            this._store$.dispatch(AuthStoreActions.AuthenticateRequest({ authModel: authModel, maskedTenantKey: this.maskedTenantKey }));
        }
        else
            this.invalidUrl(true);
    }

    invalidUrl(clearData?: boolean): void {
        if (clearData)
            this._store$.dispatch(AuthStoreActions.AuthenticateFailure({ error: 'Invalid Url' }));

        this._snackbar.open('Invalid Url', 'OK');
        this.isLoading = false;
    }
}
