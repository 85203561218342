export class ThemeColor {
  primary: string;
  secondary: string;
}

export interface Theme {
    properties: any;
}

export const defaultTheme: Theme = {
    properties: {
        "--new-patient-url-primary": "#08090A",
        "--new-patient-url-secondary": "#41474D",
    }
};
