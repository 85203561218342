<div class="component-container" style="max-width: 768px; margin: auto;">

    <div class="update-payment-logo-cont">
        <div class="update-payment-logo" *ngIf="updatePaymentSetting && updatePaymentSetting.logoLocationUrl">
            <img [src]="updatePaymentSetting.logoLocationUrl">
        </div>
    </div>
    
    <div class="patient-name">{{patient?.firstName}} {{patient?.lastName}}</div>

    <div class="update-payment-message">{{updatePaymentSetting?.message}}</div>

    <div class="col-md-12 col-sm-12 col-xs-12">
        <mat-progress-bar mode="indeterminate" *ngIf="isPaymentSaving"></mat-progress-bar>
        <form [formGroup]="paymentForm" novalidate>
            <div class="input-field-wrapper">
                <div class="input-field-cont">
                    <mat-form-field class="input-full-width patient-payment" appearance="outline">
                        <mat-label class="input-label">Card Holder Name</mat-label>
                        <input matInput
                               type="text"
                               formControlName="cardHolderName"
                               autocomplete="off"
                            >
                    </mat-form-field>
                </div>
            </div>

            <div class="input-field-wrapper">
                <div class="input-field-cont">
                    <mat-form-field class="input-full-width patient-payment" appearance="outline">
                        <mat-label class="input-label">Card Holder Address</mat-label>
                        <input matInput
                            type="text"
                            formControlName="cardHolderAddress"
                            autocomplete="off">
                    </mat-form-field>
                </div>
            </div>

            <div class="input-field-wrapper">
                <div class="input-field-cont">
                    <mat-form-field class="input-full-width patient-payment" appearance="outline">
                        <mat-label class="input-label">Card Holder Zip</mat-label>
                        <input matInput
                               type="text"
                               formControlName="cardHolderZip"
                               autocomplete="off">
                    </mat-form-field>
                </div>
            </div>
        </form>

        <div class="col-md-12 col-sm-12 col-xs-12" style="text-align: center; margin-top: 25px;">
            <button mat-raised-button
                    class="btn-primary"
                    (click)="updatePaymentMethod('CreditSaveCard')"
                    [disabled]="paymentForm.invalid || isPaymentSaving" class="payment-btn">
                    Update Credit Card
            </button>
        </div>

        <div class="col-md-12 col-sm-12 col-xs-12" style="text-align: center;">
            <button mat-raised-button
                    class="btn-primary"
                    (click)="updatePaymentMethod('AchSaveAccount')"
                    [disabled]="paymentForm.invalid || isPaymentSaving" class="payment-btn">
                Update Check
            </button>
        </div>
    </div> 
</div>