import { Component, OnDestroy, OnInit } from '@angular/core';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import emailMask from 'text-mask-addons/dist/emailMask';
import { AppointmentDetail } from '../../shared/models/appointment/AppointmentDetail';
import { filter, take, takeUntil } from 'rxjs/operators';
import { combineLatest, of, Subject } from 'rxjs';
import { AuthStoreSelectors, InsuranceCompanyStoreActions, InsuranceCompanyStoreEntity, InsuranceCompanyStoreSelectors, RootStoreState } from '../../root-store';
import { Store } from '@ngrx/store';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import * as moment from 'moment';
import * as _ from 'lodash';
import { FileParameter, GenderEnum, PatientClient, PatientDto2, PreferredContactMethodEnum, RelationshipInsuranceDto, RelationshipTypeEnum } from '../../shared/services/api.service';

@Component({
    selector: 'app-appointment-details',
    templateUrl: './appointment-details.component.html',
    styleUrls: ['./appointment-details.component.css']
})
export class AppointmentDetailsComponent implements OnInit, OnDestroy {
    insuranceFiles = [];
    coInsuranceFiles = [];
    private _destroy$: Subject<boolean> = new Subject<boolean>();
    logoLocationUrl$ = this._store$.select(AuthStoreSelectors.getLogoLocationUrl);
    footerText$ = this._store$.select(AuthStoreSelectors.getFooterText);
    appointmentDetail$ = this._store$.select(AuthStoreSelectors.getAppointmentDetail);
    insuranceCompanies$ = this._store$.select(InsuranceCompanyStoreSelectors.selectAllInsuranceCompanies);
    insuranceCompaniesIsLoading$ = this._store$.select(InsuranceCompanyStoreSelectors.selectInsuranceCompaniesIsLoading);
    emailMask = emailMask;
    phonemask: any = ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    isCheckInsurance: boolean = false;
    confirmSecondaryInsurance: boolean = false;
    showCoInsurance: boolean = false;
    isFinished: boolean = false;
    isWorking: boolean = false;
    isSavingInsurance: boolean = false;
    isSavingCoInsurance: boolean = false;
    isSubmittedCoInsurance: boolean = false;
    isSomethingMissing: boolean = false;
    appointmentDetail: AppointmentDetail;
    insuranceInformation: FormGroup;
    coInsuranceInformation: FormGroup;
    patient: PatientDto2;

    readonly today = moment().toDate();
    readonly relationshipTypes: any = _.toArray(RelationshipTypeEnum);

    insuranceCompanies: any[]=[];
    filteredInsuranceCompanies: any[]=[];
    selectedInsurance: any;
    selectedInsuranceInfo: any;

    constructor(
        private _store$: Store<RootStoreState.State>,
        private _formBuilder: FormBuilder,
        private _patientClient: PatientClient,
    ) {
    }

    ngOnInit(): void {
        this.appointmentDetail$
            .pipe(filter((d) => !!d))
            .subscribe((appointmentDetail: AppointmentDetail) => {
                this.appointmentDetail = appointmentDetail;
                return appointmentDetail;
            });

        this.insuranceCompanies$.pipe(filter(ic => !!ic)).subscribe(resp => {
            this.insuranceCompanies = resp;
            this.filteredInsuranceCompanies = resp;
        })
    }

    ngOnDestroy() {
        this._destroy$.next(true);
    }

    initializeInsuranceInformation(isInsurance: boolean): void {
        if (isInsurance) {
            this.insuranceInformation = this._formBuilder.group({
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                contactId: [null],
                relationshipId: [null],
                relationshipEtag: [null],
                relationshipType: [null, Validators.required],
                dob: [null],
                insuranceCompany: [null],
                insuranceName: [null],
                insurancePayorId: [null],
                memberId: [null],
                groupId: [null],
                email: [null],
                phone: [null],
                files: [],
            });

            this.insuranceInformation.setValidators([
                this.oneOfControlRequired(
                    this.insuranceInformation.get('insuranceCompany'),
                    this.insuranceInformation.get('insuranceName'),
                )
            ]);
        }
        else {
            this.coInsuranceInformation = this._formBuilder.group({
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                contactId: [null],
                relationshipId: [null],
                relationshipEtag: [null],
                relationshipType: [null, Validators.required],
                dob: [null],
                insuranceCompany: [null],
                insuranceName: [null],
                insurancePayorId: [null],
                memberId: [null],
                groupId: [null],
                email: [null],
                phone: [null],
                files: [],
            });

            this.coInsuranceInformation.setValidators([
                this.oneOfControlRequired(
                    this.coInsuranceInformation.get('insuranceCompany'),
                    this.coInsuranceInformation.get('insuranceName'),
                )
            ]);
        }
    }

    saveInsurance(): void {
        if (!this.insuranceInformation || !this.insuranceInformation.value) return;

        if (!this.insuranceInformation.valid) {
            this.isSomethingMissing = true;
            return;
        }

        this.isSavingInsurance = true;
        let relationshipInsurance: RelationshipInsuranceDto = this.getRelationshipInsuranceData(this.insuranceInformation.value);
        let patientInsurances: FileParameter[] = this.getInsuranceFiles(this.insuranceInformation.value);

        combineLatest([
            this._patientClient.patient_PostRelationshipInsurance(
                relationshipInsurance.patientId,
                relationshipInsurance),
            patientInsurances.length > 0 ?
                this._patientClient.patient_PostPatientInsuranceFiles(
                    relationshipInsurance.patientId,
                    patientInsurances) :
                of(null)
        ]).pipe(
                take(1),
                takeUntil(this._destroy$))
            .subscribe(
                () => {
                    this.isSavingInsurance = false;
                    this.navigateTo("confirmSecondaryInsurance");
                },
                (err) => {
                    this.isSavingInsurance = false;
                }
            );
    }

    saveCoInsurance(): void {
        if (!this.coInsuranceInformation || !this.coInsuranceInformation.value) return;

        if (!this.coInsuranceInformation.valid) {
            this.isSomethingMissing = true;
            return;
        }

        this.isSavingCoInsurance = true;
        let relationshipInsurance: RelationshipInsuranceDto = this.getRelationshipInsuranceData(this.coInsuranceInformation.value);
        let patientInsurances: FileParameter[] = this.getInsuranceFiles(this.coInsuranceInformation.value);

        combineLatest([
            this._patientClient.patient_PostRelationshipInsurance(
                relationshipInsurance.patientId,
                relationshipInsurance),
            patientInsurances.length > 0 ?
                this._patientClient.patient_PostPatientInsuranceFiles(
                    relationshipInsurance.patientId,
                    patientInsurances) :
                of(null)
        ]).pipe(
                take(1),
                takeUntil(this._destroy$))
            .subscribe(
                () => {
                    this.isSavingCoInsurance = false;
                    this.isSubmittedCoInsurance = true;
                },
                (err) => {
                    this.isSavingCoInsurance = false;
                }
            );
    }

    private getInsuranceFiles(insuranceInformation: any): FileParameter[] {
        let files: FileParameter[] = [];
        if (insuranceInformation.files && insuranceInformation.files.length > 0) {
            _.each(insuranceInformation.files, (f: File, i: number) => {
                const data = new Blob([f], { type: f.type })
                files.push({
                    data,
                    fileName: f.name.replace(new RegExp('/', 'g'), '_'),
                });
            });

        }

        return files;
    }

    private getRelationshipInsuranceData(insuranceInformation: any): RelationshipInsuranceDto {
        let relationship: RelationshipInsuranceDto = new RelationshipInsuranceDto({
            id: insuranceInformation.relationshipId,
            contactId: insuranceInformation.contactId,
            patientId: this.appointmentDetail.patientId,
            relationshipType: insuranceInformation.relationshipType,
            firstName: insuranceInformation.firstName,
            lastName: insuranceInformation.lastName,
            dob: insuranceInformation.dob,
            insuranceCompanyId: insuranceInformation.insuranceCompany ? insuranceInformation.insuranceCompany.id : 0,
            groupPolicyNumber: insuranceInformation.groupId,
            individualPolicyNumber: insuranceInformation.memberId,
            insuranceNotes: this.getInsuranceNotes(insuranceInformation),
            gender: GenderEnum.Unknown,
            preferredContactMethod: PreferredContactMethodEnum.Email,
            hasLedgers: false,
            hasNotifications: false,
            isSecureAccess: false,
            eTag: insuranceInformation.relationshipEtag,
        });

        return relationship;
    }

    private getInsuranceNotes(insuranceInformation: any): string {
        if (insuranceInformation &&
            insuranceInformation.insuranceCompany &&
            insuranceInformation.insuranceCompany.name == "Other") {
            return `Ins Name: ${insuranceInformation.insuranceName} Ins Payor Id: ${insuranceInformation.insurancePayorId}`;
        }
        return null;
    }

    birthDateChanged(insuranceInformation: FormGroup, date: any) {
        insuranceInformation.patchValue({
            dob: date
        });
    }

    insuranceTypeChanged(insuranceInformation: FormGroup, type: string): void {
        switch (type) {
            case 'patient': {
                insuranceInformation.patchValue({
                    firstName: this.appointmentDetail.patientFirstName,
                    lastName: this.appointmentDetail.patientLastName,
                    relationshipType: 'Patient',
                    dob: this.appointmentDetail.patientDOB,
                    contactId: this.appointmentDetail.patientContactId,
                    relationshipId: this.appointmentDetail.patientRelationshipId,
                    relationshipEtag: this.appointmentDetail.patientRelationshipEtag,
                });
                break;
            }
            case 'responsible': {
                insuranceInformation.patchValue({
                    firstName: this.appointmentDetail.contactFirstName,
                    lastName: this.appointmentDetail.contactLastName,
                    relationshipType: this.appointmentDetail.contactRelationshipType,
                    dob: null,
                    contactId: this.appointmentDetail.contactId,
                    relationshipId: this.appointmentDetail.contactRelationshipId,
                    relationshipEtag: this.appointmentDetail.contactRelationshipEtag,
                });
                break;
            }
            case 'other': {
                insuranceInformation.patchValue({
                    firstName: '',
                    lastName: '',
                    relationshipType: 'Other',
                    dob: null,
                    contactId: null,
                    relationshipId: null,
                    relationshipEtag: null,
                });
                break;
            }
        }
    }

    navigateTo(section) {
        switch (section) {
            case 'confirmation': {
                this.isFinished = true;
                break;
            }
            case 'insurance': {
                this._store$.dispatch(InsuranceCompanyStoreActions.LoadRequest({}));
                this.initializeInsuranceInformation(true);
                this.isCheckInsurance = true;
                break;
            }
            case 'confirmSecondaryInsurance': {
                this.confirmSecondaryInsurance = true;
                break;
            }
            case 'coinsurance': {
                this.initializeInsuranceInformation(false);
                this.showCoInsurance = true;
                break;
            }
        }
    }

    selectInsuranceCompany(insuranceInformation: FormGroup, insuranceCompany:InsuranceCompanyStoreEntity){
        

        if(insuranceInformation == this.insuranceInformation){
            this.selectedInsuranceInfo = insuranceCompany.name;
        } else {
            this.selectedInsurance = insuranceCompany.name;
        }

        insuranceInformation.patchValue({
            insuranceName: insuranceCompany.name,
            insuranceCompany: insuranceCompany
        })
    }

    insuranceFileChanged(event): void {
        this.insuranceInformation.get('files').setValue([...event]);
        this.insuranceFiles = this.insuranceInformation.value.files;
        this.updateInsuranceValidation();
    }

    coInsuranceFileChanged(event): void {
        this.coInsuranceInformation.get('files').setValue([...event]);
        this.coInsuranceFiles = this.coInsuranceInformation.value.files;
        this.updateCoInsuranceValidation();
    }

    getAttachedInsuranceFiles(): File[] {
        return this.insuranceInformation.get('files').value as File[];
    }

    getAttachedCoInsuranceFiles(): File[] {
        return this.coInsuranceInformation.get('files').value as File[];
    }

    removeAttachedInsuranceFile(i: number): void {
        let attachments = this.getAttachedInsuranceFiles();
        if (!attachments || attachments.length < 1) return;
        if (i > -1) {
            attachments.splice(i, 1);
            this.insuranceFiles.splice(i, 1);
        }
        this.insuranceInformation.get('files').setValue(attachments);
        this.updateInsuranceValidation()
    }

    removeAttachedCoInsuranceFile(i: number): void {
        let attachments = this.getAttachedCoInsuranceFiles();
        if (!attachments || attachments.length < 1) return;
        if (i > -1) {
            attachments.splice(i, 1);
            this.coInsuranceFiles.splice(i, 1);
        }
        this.coInsuranceInformation.get('files').setValue(attachments);
        this.updateCoInsuranceValidation();
    }

    updateInsuranceValidation(): void {
        if (this.insuranceFiles.length < 1) {
            this.insuranceInformation.setValidators([
                this.oneOfControlRequired(
                    this.insuranceInformation.get('insuranceCompany'),
                    this.insuranceInformation.get('insuranceName'),
                )
            ]);
        }
        else {
            this.insuranceInformation.clearValidators();
        }

        this.insuranceInformation.updateValueAndValidity();
    }

    updateCoInsuranceValidation(): void {
        if (this.coInsuranceFiles.length < 1) {
            this.coInsuranceInformation.setValidators([
                this.oneOfControlRequired(
                    this.coInsuranceInformation.get('insuranceCompany'),
                    this.coInsuranceInformation.get('insuranceName'),
                )
            ]);
        }
        else {
            this.coInsuranceInformation.clearValidators();
        }

        this.coInsuranceInformation.updateValueAndValidity();
    }

    oneOfControlRequired(...controls: AbstractControl[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            for (const aControl of controls) {
                if (!Validators.required(aControl)) {
                    return null;
                }
            }
            return { oneOfRequired: true };
        };
    }

    searchInsurance(evt) {
        let searchkeyword: any = evt.target.value;
        if (searchkeyword.length > 0) {
          this.filteredInsuranceCompanies = this.insuranceCompanies.filter(option => (option.name.toLowerCase().includes(searchkeyword.toLowerCase()) || option.shortName.toLowerCase().includes(searchkeyword.toLowerCase()) || (option.payorNumber && option.payorNumber.toLowerCase().includes(searchkeyword.toLowerCase()))));
          if (this.filteredInsuranceCompanies.length == 0) {
            this.filteredInsuranceCompanies = this.insuranceCompanies;
          }
    
        } else {
          this.filteredInsuranceCompanies = this.insuranceCompanies;
        }
    }
}
