<div class="main-container">
    <mat-progress-bar mode="indeterminate" *ngIf="isWorking"></mat-progress-bar>
    <div class="right-panel container" 
         *ngIf="!isWorking">
        <ng-container *ngIf="location">
            <div class="row d-none d-sm-flex">
                <div class="col">
                    <img [src]="location.profileUrl"
                         class="logo"
                         *ngIf="location.profileUrl" />
                </div>
                <div class="col"
                     style="text-align: center;">
                    <div class="office-name">{{location.name}}</div>
                </div>
                <div class="col" style="text-align: end;">
                    <div class="office-addr">
                        <div *ngIf="location.address">{{location.address.line1}} {{location.address.line2}}</div>
                        <div *ngIf="location.address">{{location.address.city}}, {{location.address.state}}</div>
                        <div>{{location.phoneNumber}}</div>
                    </div>
                </div>
            </div>
            <div class="row d-block d-sm-none">
                <div class="col sm-header">
                    <img [src]="location.profileUrl"
                         class="logo"
                         *ngIf="location.profileUrl" />
                </div>
            </div>
            <div class="row d-block d-sm-none"
                 style="padding-bottom: 20px;">
                <div class="col sm-header">
                    <div class="office-name">{{location.name}}</div>
                </div>
            </div>
            <div class="row d-block d-sm-none">
                <div class="col sm-header">
                    <div class="office-addr"
                         style="text-align: center;">
                        <div *ngIf="location.address">{{location.address.line1}} {{location.address.line2}}</div>
                        <div *ngIf="location.address">{{location.address.city}}, {{location.address.state}}</div>
                        <div>{{location.phoneNumber}}</div>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="row">
            <div class="col-12">
                <div class="patient-row">Treatment Options for {{patient?.firstName}} {{patient?.lastName}}</div>
            </div>
        </div>
        <div class="d-block d-sm-none">
            <div class="row">
                <div class="col-12">
                    <mat-form-field style="width: 100%">
                        <mat-label>Treatment</mat-label>
                        <mat-select [(value)]="selectedTreatmentPlan"
                                    (selectionChange)="treatmentChange($event.value)">
                            <mat-option [value]="treatment"
                                        *ngFor="let treatment of selectedTreatmentPlans">
                                {{treatment.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <ng-container *ngIf="selectedTreatmentPlan">
                <div class="row"
                     style="margin-bottom: 10px;">
                    <div class="col-12">
                        <div class="info-panel"
                             *ngIf="selectedTreatmentPlan && selectedTreatmentPlan.coverages && selectedTreatmentPlan.coverages.length">
                            <div class="info-title">Your orthodontic investment includes:</div>
                            <ng-container *ngFor="let coverage of selectedTreatmentPlan.coverages">
                                <div class="info-txt"
                                     *ngIf="coverage.isSelected">
                                    <mat-icon style="font-size: 16px; margin-right: 3px;">check</mat-icon>{{coverage.description}}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="row"
                     style="border-bottom: 1px solid #A5A5A5; padding-bottom: 2px;">
                    <div class="col-12">
                        <div class="selected-plan-name">
                            {{selectedTreatmentPlan.name}}
                        </div>
                        <div class="selected-plan-estimated-time">
                            estimated time: {{selectedTreatmentPlan.treatmentLengthMonths}}m
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <div class="plan-element-label">Records fee</div>
                    </div>
                    <div class="col-7">
                        <div class="plan-element-value">
                            {{selectedTreatmentPlan.recordsFee | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <div class="plan-element-label">Treatment fee</div>
                    </div>
                    <div class="col-7">
                        <div class="plan-element-value">
                            {{selectedTreatmentPlan.treatmentFee | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <div class="plan-element-label-header">
                            Additional options
                        </div>
                    </div>
                    <div class="col-7">
                    </div>
                </div>
                <div class="row"
                     *ngFor="let feeItem of selectedTreatmentPlan.fees">
                    <div class="col-5">
                        <div class="other-options">
                            {{feeItem.name}}
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="plan-element-value">
                            {{feeItem.flatAmount | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
                <div class="row total-fee-row">
                    <div class="col-5">
                        <div class="plan-element-label"
                             style="font-weight: bold;">
                            Total Fee
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="plan-element-value"
                             style="font-weight: bold;">
                            {{selectedTreatmentPlan.total | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <div class="plan-element-label-header">
                            Adjustments
                        </div>
                    </div>
                    <div class="col-7">
                    </div>
                </div>
                <div class="row"
                     *ngFor="let discount of selectedTreatmentPlan.discounts">
                    <div class="col-5">
                        <div class="other-options">
                            {{discount.name}}
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="plan-element-value"
                             style="color: #FD7474;">
                            {{discount.flatAmount | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <div class="plan-element-label-header">
                            Insurance estimates
                        </div>
                    </div>
                    <div class="col-7">
                    </div>
                </div>
                <div class="row"
                     *ngFor="let insurance of selectedTreatmentPlan.insurances">
                    <div class="col-5">
                        <div class="other-options">
                            {{insurance.name}}
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="plan-element-value"
                             style="color: #FD7474;">
                            {{insurance.amount | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
                <div class="row"
                     style="border-top: 1px solid #A5A5A5;">
                    <div class="col-5">
                        <div class="plan-element-label"
                             style="font-weight: bold; color: #0ED251; border-right: transparent;">
                            Your portion:
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="plan-element-value"
                             style="font-weight: bold; color: #0ED251; font-size: 16px;">
                            {{selectedTreatmentPlan.patientPortion | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="customize-plan-heading">Customize Your Payment Plan</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div [ngClass]="{'customize-plan-tab': true, 'customize-plan-tab-selected': selectedCustomizePlanTab == 'cash'}"
                             (click)="selectCustomizePlanTab('cash')">
                            <div>Pay in Full-cash</div>
                            <!-- <div>({{selectedTreatmentPlan.pifA}}% discount)</div> -->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div [ngClass]="{'customize-plan-tab': true, 'customize-plan-tab-selected': selectedCustomizePlanTab == 'cc'}"
                             (click)="selectCustomizePlanTab('cc')">
                            <div>Pay in full-credit card</div>
                            <!-- <div>({{selectedTreatmentPlan.pifB}}% discount)</div> -->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div [ngClass]="{'customize-plan-tab': true, 'customize-plan-tab-selected': selectedCustomizePlanTab == 'officeFinance'}"
                             (click)="selectCustomizePlanTab('officeFinance')">
                            <!-- <div>Interest Free</div> -->
                            <div>In-Office Financing</div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="(selectedCustomizePlanTab == 'cc' || selectedCustomizePlanTab == 'cash') && selectedTreatmentPlan">
                    <div class="row" style="padding-top: 20px;">
                        <div class="col-12" style="text-align:center">
                            <div class="all-element">
                                <div class="all-element-label">patient portion</div>
                                <div class="all-element-value">{{selectedTreatmentPlan.patientPortion | number: '0.2-2'}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" style="text-align:center">
                            <div class="all-element">
                                <div class="all-element-label">&nbsp;</div>
                                <div class="all-element-value">&mdash;</div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 20px;">
                        <div class="col-12" style="text-align:center">
                            <div class="all-element">
                                <div class="all-element-label">paid in full discount</div>
                                <div class="all-element-value"
                                     *ngIf="selectedCustomizePlanTab == 'cc'">
                                    {{selectedTreatmentPlan.pifBDiscountAmount | number: '0.2-2'}}
                                </div>
                                <div class="all-element-value"
                                     *ngIf="selectedCustomizePlanTab == 'cash'">
                                    {{selectedTreatmentPlan.pifADiscountAmount | number: '0.2-2'}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" style="text-align:center">
                            <div class="all-element">
                                <div class="all-element-label">&nbsp;</div>
                                <div class="all-element-value" style="font-size: 16px; margin-top: -3px;">+</div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 20px;">
                        <div class="col-12" style="text-align:center">
                            <div class="all-element">
                                <div class="all-element-label">insurance estimate*</div>
                                <div class="all-element-value">{{selectedTreatmentPlan.insuranceEstimates ? (selectedTreatmentPlan.insuranceEstimates | number: '0.2-2') : 0}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 20px;">
                        <div class="col-12" style="text-align:center">
                            <div class="col-12" style="text-align: center">
                                <div class="all-element" style="padding-right: 20px;">
                                    <div class="all-element-label">due now</div>
                                    <div class="all-element-value" style="color: #0ED251; font-size: 20px;">
                                        {{getDueNow() | number: '0.2-2'}}
                                    </div>
                                </div>
                                <div class="all-element">
                                    <div class="all-element-label">total contract</div>
                                    <div class="all-element-value" style="color: #0ED251; font-size: 20px;">
                                      {{getPifTotalContract() | number: '0.2-2'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="insurance-info">*Insurance is just an estimate. You are responsible for any amounts insurance does not pay</div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="selectedCustomizePlanTab == 'officeFinance' && selectedTreatmentPlan">
                    <div class="row" style="padding-top: 20px;">
                        <div class="col-12">
                            <div style="display: flex;">
                                <div class="progress-value">{{selectedTreatmentPlan.amountDown | number: '0.2-2'}}</div>
                                <mat-icon style="padding-left: 10px; cursor: pointer;"
                                          class="icon-evolution-color"
                                          (click)="editDownMonths()">
                                    edit
                                </mat-icon>
                            </div>
                            <div class="range">
                                <input type="range"
                                       [min]="selectedTreatmentPlan.eQuoteMinimumDownAmount ? selectedTreatmentPlan.eQuoteMinimumDownAmount : 1"
                                       [max]="selectedTreatmentPlan.patientPortion"
                                       step="0.1"
                                       [value]="selectedTreatmentPlan.amountDown"
                                       (input)="changeCustomAmountDown($event.target.value)" />
                            </div>

                            <div class="office-finance-patient-portion">down payment</div>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 20px;">
                        <div class="col-12">
                            <div style="display: flex;">
                                <div class="progress-value">{{selectedTreatmentPlan.paymentAmountMonthly | number: '0.2-2'}}</div>
                                <mat-icon style="padding-left: 10px; cursor:pointer;"
                                          class="icon-evolution-color"
                                          (click)="editDownMonths()">
                                    edit
                                </mat-icon>
                            </div>
                            <div class="range">
                                <input type="range"
                                       [min]="selectedTreatmentPlan.eQuoteMinMonths ? selectedTreatmentPlan.eQuoteMinMonths : 1"
                                       [max]="selectedTreatmentPlan.eQuoteMaxMonths ? selectedTreatmentPlan.eQuoteMaxMonths : selectedTreatmentPlan.treatmentLengthMonths+12"
                                       step="1"
                                       [value]="selectedTreatmentPlan.paymentTermsMonths"
                                       (input)="changeCustomTreamentLengh($event.target.value)" />
                            </div>
                            <div class="office-finance-patient-portion">
                                number of months <span style="font-weight: bold;">{{selectedTreatmentPlan.paymentTermsMonths}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row" style="padding-top: 20px;">
                        <div class="col-12" style="text-align:center">
                            <div class="all-element">
                                <div class="all-element-label">patient portion</div>
                                <div class="all-element-value">{{selectedTreatmentPlan.patientPortion | number: '0.2-2'}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" style="text-align:center">
                            <div class="all-element">
                                <div class="all-element-label">&nbsp;</div>
                                <div class="all-element-value" style="font-size: 16px; margin-top: -3px;">+</div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 20px;">
                        <div class="col-12" style="text-align:center">
                            <div class="all-element">
                                <div class="all-element-label">insurance estimate*</div>
                                <div class="all-element-value">{{selectedTreatmentPlan.insuranceEstimates ? (selectedTreatmentPlan.insuranceEstimates | number: '0.2-2') : 0}}</div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="selectedTreatmentPlan.paymentTermsMonths >= selectedTreatmentPlan.managementFeeThresholdInMonths && selectedTreatmentPlan.managementFeeAmount">
                        <div class="row">
                            <div class="col-12" style="text-align:center">
                                <div class="all-element">
                                    <div class="all-element-label">&nbsp;</div>
                                    <div class="all-element-value" style="font-size: 16px; margin-top: -3px;">+</div>
                                </div>
                            </div>
                        </div>

                        <div class="row" style="padding-top: 20px;">
                            <div class="col-12" style="text-align:center">
                                <div class="all-element">
                                    <div class="all-element-label">management fee</div>
                                    <div class="all-element-value" style="color: #000000;">{{selectedTreatmentPlan.managementFeeAmount ? (selectedTreatmentPlan.managementFeeAmount | number: '0.2-2') : 0}}</div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="row" style="padding-top: 20px;">
                        <div class="col-12" style="text-align: center">
                            <div class="all-element" style="padding-right: 20px;">
                                <div class="all-element-label">due now</div>
                                <div class="all-element-value" style="color: #0ED251; font-size: 20px;">
                                    {{selectedTreatmentPlan.amountDown | number: '0.2-2'}}
                                </div>
                            </div>
                            <div class="all-element">
                                <div class="all-element-label">total contract</div>
                                <div class="all-element-value" style="color: #0ED251; font-size: 20px;">{{interestFreeCalculation() | number: '0.2-2'}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="insurance-info">*Insurance is just an estimate. You are responsible for any amounts insurance does not pay</div>
                        </div>
                    </div>
                </ng-container>

                <div class="row" style="padding-top: 20px;">
                    <div class="col-6">
                        <button mat-flat-button
                                color="case-presentation"
                                (click)="notNow()">
                            Not now
                        </button>
                    </div>

                    <div class="col-6" style="text-align: right">
                        <button mat-flat-button 
                                color="case-presentation"
                                (click)="signAndPay()">
                            Sign Now
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="d-none d-sm-block">
            <div class="row"
                 style="margin-bottom: 10px;">
                <div class="col-2">

                </div>
                <div class="col-5">
                    <div class="info-panel"
                         *ngIf="selectedTreatmentPlans[0] && selectedTreatmentPlans[0].coverages && selectedTreatmentPlans[0].coverages.length">
                        <div class="info-title">Your orthodontic investment includes:</div>
                        <ng-container *ngFor="let coverage of selectedTreatmentPlans[0].coverages">
                            <div class="info-txt"
                                 *ngIf="coverage.isSelected">
                                <mat-icon style="font-size: 16px; margin-right: 3px;">check</mat-icon>{{coverage.description}}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-5">
                    <div class="info-panel"
                         *ngIf="selectedTreatmentPlans[1] && selectedTreatmentPlans[1].coverages && selectedTreatmentPlans[1].coverages.length">
                        <div class="info-title">Your orthodontic investment includes:</div>
                        <ng-container *ngFor="let coverage of selectedTreatmentPlans[1].coverages">
                            <div class="info-txt"
                                 *ngIf="coverage.isSelected">
                                <mat-icon style="font-size: 16px; margin-right: 3px;">check</mat-icon>{{coverage.description}}
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row"
                 style="border-bottom: 1px solid #A5A5A5; padding-bottom: 2px;">
                <div class="col-2">

                </div>
                <div class="col-5">
                    <div class="selected-plan-name"
                         *ngIf="selectedTreatmentPlans[0]">
                        {{selectedTreatmentPlans[0].name}}
                    </div>
                    <div class="selected-plan-estimated-time"
                         *ngIf="selectedTreatmentPlans[0]">
                        estimated time: {{selectedTreatmentPlans[0].treatmentLengthMonths}}m
                    </div>
                </div>
                <div class="col-5">
                    <div class="selected-plan-name"
                         *ngIf="selectedTreatmentPlans[1]">
                        {{selectedTreatmentPlans[1].name}}
                    </div>
                    <div class="selected-plan-estimated-time"
                         *ngIf="selectedTreatmentPlans[1]">
                        estimated time: {{selectedTreatmentPlans[1].treatmentLengthMonths}}m
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="plan-element-label">Records fee</div>
                </div>
                <div class="col-5">
                    <div class="plan-element-value"
                         *ngIf="selectedTreatmentPlans[0]">
                        {{selectedTreatmentPlans[0].recordsFee | number: '0.2-2'}}
                    </div>
                </div>
                <div class="col-5">
                    <div class="plan-element-value"
                         *ngIf="selectedTreatmentPlans[1]">
                        {{selectedTreatmentPlans[1].recordsFee | number: '0.2-2'}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="plan-element-label">Treatment fee</div>
                </div>
                <div class="col-5">
                    <div class="plan-element-value"
                         *ngIf="selectedTreatmentPlans[0]">
                        {{selectedTreatmentPlans[0].treatmentFee | number: '0.2-2'}}
                    </div>
                </div>
                <div class="col-5">
                    <div class="plan-element-value"
                         *ngIf="selectedTreatmentPlans[1]">
                        {{selectedTreatmentPlans[1].treatmentFee | number: '0.2-2'}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="plan-element-label-header">
                        Additional options
                    </div>
                </div>
                <div class="col-5">
                </div>
                <div class="col-5">
                </div>
            </div>
            <ng-container *ngIf="fees">
                <div class="row"
                     *ngFor="let feeItem of fees">
                    <div class="col-2">
                        <div class="other-options">
                            {{feeItem.name}}
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="plan-element-value">
                            {{feeItem.flatAmount | number: '0.2-2'}}
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="plan-element-value">
                            {{feeItem.flatAmount2 | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="row total-fee-row">
                <div class="col-2">
                    <div class="plan-element-label"
                         style="font-weight: bold;">
                        Total Fee
                    </div>
                </div>
                <div class="col-5">
                    <div class="plan-element-value"
                         *ngIf="selectedTreatmentPlans[0]"
                         style="font-weight: bold;">
                        {{selectedTreatmentPlans[0].total | number: '0.2-2'}}
                    </div>
                </div>
                <div class="col-5">
                    <div class="plan-element-value"
                         *ngIf="selectedTreatmentPlans[1]"
                         style="font-weight: bold;">
                        {{selectedTreatmentPlans[1].total | number: '0.2-2'}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="plan-element-label-header">
                        Adjustments
                    </div>
                </div>
                <div class="col-5">
                </div>
                <div class="col-5">
                </div>
            </div>
            <ng-container *ngIf="discounts">
                <div class="row"
                     *ngFor="let discount of discounts">
                    <div class="col-2">
                        <div class="other-options">
                            {{discount.name}}
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="plan-element-value"
                             style="color: #FD7474;">
                            {{discount.flatAmount | number: '0.2-2'}}
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="plan-element-value"
                             style="color: #FD7474;">
                            {{discount.flatAmount2 | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="row">
                <div class="col-2">
                    <div class="plan-element-label-header">
                        Insurance estimates
                    </div>
                </div>
                <div class="col-5">
                </div>
                <div class="col-5">
                </div>
            </div>
            <ng-container *ngIf="insurances">
                <div class="row"
                     *ngFor="let insurance of insurances">
                    <div class="col-2">
                        <div class="other-options">
                            {{insurance.name}}
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="plan-element-value"
                             style="color: #FD7474;">
                            {{insurance.amount | number: '0.2-2'}}
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="plan-element-value"
                             style="color: #FD7474;">
                            {{insurance.amount2 | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="row" style="border-top: 1px solid #A5A5A5;">
                <div class="col-2">
                    <div class="plan-element-label"
                         style="font-weight: bold; color: #0ED251; border-right: transparent;">
                        Your portion:
                    </div>
                </div>
                <div class="col-5">
                    <ng-container *ngIf="selectedTreatmentPlans[0]">
                        <div class="plan-element-value"
                             style="font-weight: bold; color: #0ED251; font-size: 16px;">
                            {{selectedTreatmentPlans[0].patientPortion | number: '0.2-2'}}
                        </div>

                        <div style="text-align: center;">
                            <button mat-icon-button (click)="selectPlan(0)">
                                <fa-icon style="color: #3F91CD; font-size: 24px;"
                                         [icon]="faSquare"
                                         *ngIf="!selectedTreatmentPlans[0].isSelected">
                                </fa-icon>
                                <fa-icon style="color: #3F91CD; font-size: 24px;"
                                         [icon]="faCheckCircle"
                                         *ngIf="selectedTreatmentPlans[0].isSelected">
                                </fa-icon>
                            </button>
                        </div>
                    </ng-container>
                </div>
                <div class="col-5">
                    <ng-container *ngIf="selectedTreatmentPlans[1]">
                        <div class="plan-element-value"
                             style="font-weight: bold; color: #0ED251; font-size: 16px;">
                            {{selectedTreatmentPlans[1].patientPortion | number: '0.2-2'}}
                        </div>

                        <div style="text-align: center;">
                            <button mat-icon-button (click)="selectPlan(1)">
                                <fa-icon style="color: #3F91CD; font-size: 24px;"
                                         [icon]="faSquare"
                                         *ngIf="!selectedTreatmentPlans[1].isSelected">
                                </fa-icon>
                                <fa-icon style="color: #3F91CD; font-size: 24px;"
                                         [icon]="faCheckCircle"
                                         *ngIf="selectedTreatmentPlans[1].isSelected">
                                </fa-icon>
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="customize-plan-heading">Customize Your Payment Plan</div>
                </div>
            </div>
            <ng-container *ngIf="selectedTreatmentPlan">
                <div class="row">
                    <div class="col-4">
                        <div [ngClass]="{'customize-plan-tab': true, 'customize-plan-tab-selected': selectedCustomizePlanTab == 'cash'}"
                             (click)="selectCustomizePlanTab('cash')">
                            <div>Pay in Full-cash</div>
                            <!-- <div>({{selectedTreatmentPlan.pifA}}% discount)</div> -->
                        </div>
                    </div>
                    <div class="col-4">
                        <div [ngClass]="{'customize-plan-tab': true, 'customize-plan-tab-selected': selectedCustomizePlanTab == 'cc'}"
                             (click)="selectCustomizePlanTab('cc')">
                            <div>Pay in full-credit card</div>
                            <!-- <div>({{selectedTreatmentPlan.pifB}}% discount)</div> -->
                        </div>
                    </div>
                    <div class="col-4">
                        <div [ngClass]="{'customize-plan-tab': true, 'customize-plan-tab-selected': selectedCustomizePlanTab == 'officeFinance'}"
                             (click)="selectCustomizePlanTab('officeFinance')">
                            <!-- <div>Interest Free</div> -->
                            <div>In-Office Financing</div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="(selectedCustomizePlanTab == 'cc' || selectedCustomizePlanTab == 'cash') && selectedTreatmentPlan">
                <div class="row" style="padding-top: 20px;">
                    <div class="col-7">
                        <div class="all-element">
                            <div class="all-element-label">patient portion</div>
                            <div class="all-element-value">{{selectedTreatmentPlan.patientPortion | number: '0.2-2'}}</div>
                        </div>

                        <div class="all-element">
                            <div class="all-element-label">&nbsp;</div>
                            <div class="all-element-value">&mdash;</div>
                        </div>

                        <div class="all-element">
                            <div class="all-element-label">paid in full discount</div>
                            <div class="all-element-value"
                                 *ngIf="selectedCustomizePlanTab == 'cc'">
                                {{selectedTreatmentPlan.pifBDiscountAmount | number: '0.2-2'}}
                            </div>
                            <div class="all-element-value"
                                 *ngIf="selectedCustomizePlanTab == 'cash'">
                                {{selectedTreatmentPlan.pifADiscountAmount | number: '0.2-2'}}
                            </div>
                        </div>

                        <div class="all-element">
                            <div class="all-element-label">&nbsp;</div>
                            <div class="all-element-value" style="font-size: 16px; margin-top: -3px;">+</div>
                        </div>

                        <div class="all-element">
                            <div class="all-element-label">insurance estimate*</div>
                            <div class="all-element-value">{{selectedTreatmentPlan.insuranceEstimates ? (selectedTreatmentPlan.insuranceEstimates | number: '0.2-2') : 0}}</div>
                        </div>
                    </div>
                    <div class="col-5" style="text-align: right">
                        <div class="all-element" style="padding-right: 20px;">
                            <div class="all-element-label">due now</div>
                            <div class="all-element-value" style="color: #0ED251; font-size: 20px;">
                                {{getDueNow() | number: '0.2-2'}}
                            </div>
                        </div>
                        <div class="all-element">
                            <div class="all-element-label">total contract</div>
                            <div class="all-element-value" style="color: #0ED251; font-size: 20px;">
                              {{getPifTotalContract() | number: '0.2-2'}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="insurance-info">*Insurance is just an estimate. You are responsible for any amounts insurance does not pay</div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="selectedCustomizePlanTab == 'officeFinance' && selectedTreatmentPlan">
                <div class="row" style="padding-top: 20px;">
                    <div class="col-12">
                        <div style="display: flex;">
                            <div class="progress-value">{{selectedTreatmentPlan.amountDown | number: '0.2-2'}}</div>
                            <mat-icon style="padding-left: 10px; cursor: pointer;"
                                      class="icon-evolution-color"
                                      (click)="editDownMonths()">
                                edit
                            </mat-icon>
                        </div>
                        
                        <div class="range">
                            <input type="range"
                                   [min]="selectedTreatmentPlan.eQuoteMinimumDownAmount ? selectedTreatmentPlan.eQuoteMinimumDownAmount : 1"
                                   [max]="selectedTreatmentPlan.patientPortion"
                                   step="1"
                                   [value]="selectedTreatmentPlan.amountDown"
                                   (input)="changeCustomAmountDown($event.target.value)" />
                        </div>

                        <div class="office-finance-patient-portion">down payment</div>
                    </div>
                </div>
                <div class="row" style="padding-top: 20px;">
                    <div class="col-12">
                        <div style="display: flex;">
                            <div class="progress-value">{{selectedTreatmentPlan.paymentAmountMonthly | number: '0.2-2'}}</div>
                            <mat-icon style="padding-left: 10px; cursor: pointer;"
                                      class="icon-evolution-color"
                                      (click)="editDownMonths()">
                                edit
                            </mat-icon>
                        </div>
                        <div class="range">
                            <input type="range"
                                   [min]="selectedTreatmentPlan.eQuoteMinMonths ? selectedTreatmentPlan.eQuoteMinMonths : 1"
                                   [max]="selectedTreatmentPlan.eQuoteMaxMonths ? selectedTreatmentPlan.eQuoteMaxMonths : selectedTreatmentPlan.treatmentLengthMonths+12"
                                   step="1"
                                   [value]="selectedTreatmentPlan.paymentTermsMonths"
                                   (input)="changeCustomTreamentLengh($event.target.value)" />
                        </div>
                        <div class="office-finance-patient-portion">number of months <span style="font-weight: bold;">{{selectedTreatmentPlan.paymentTermsMonths}}</span></div>
                    </div>
                </div>
                <div class="row" style="padding-top: 20px;">
                    <div class="col-7">
                        <div class="all-element">
                            <div class="all-element-label">patient portion</div>
                            <div class="all-element-value">{{selectedTreatmentPlan.patientPortion | number: '0.2-2'}}</div>
                        </div>

                        <div class="all-element">
                            <div class="all-element-label">&nbsp;</div>
                            <div class="all-element-value" style="font-size: 16px; margin-top: -3px;">+</div>
                        </div>

                        <div class="all-element">
                            <div class="all-element-label">insurance estimate*</div>
                            <div class="all-element-value">{{selectedTreatmentPlan.insuranceEstimates ? (selectedTreatmentPlan.insuranceEstimates | number: '0.2-2') : 0}}</div>
                        </div>

                        <ng-container *ngIf="selectedTreatmentPlan.paymentTermsMonths >= selectedTreatmentPlan.managementFeeThresholdInMonths && selectedTreatmentPlan.managementFeeAmount">
                            <div class="all-element">
                                <div class="all-element-label">&nbsp;</div>
                                <div class="all-element-value" style="font-size: 16px; margin-top: -3px;">+</div>
                            </div>

                            <div class="all-element">
                                <div class="all-element-label">management fee</div>
                                <div class="all-element-value" style="color: #000000;">{{selectedTreatmentPlan.managementFeeAmount ? (selectedTreatmentPlan.managementFeeAmount | number: '0.2-2') : 0}}</div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-5" style="text-align: right">
                        <div class="all-element" style="padding-right: 20px;">
                            <div class="all-element-label">due now</div>
                            <div class="all-element-value" style="color: #0ED251; font-size: 20px;">
                                {{selectedTreatmentPlan.amountDown | number: '0.2-2'}}
                            </div>
                        </div>
                        <div class="all-element">
                            <div class="all-element-label">total contract</div>
                            <div class="all-element-value" style="color: #0ED251; font-size: 20px;">{{interestFreeCalculation() | number: '0.2-2'}}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="insurance-info">*Insurance is just an estimate. You are responsible for any amounts insurance does not pay</div>
                    </div>
                </div>
            </ng-container>

            <div class="row" style="padding-top: 20px;">
                <div class="col-6">
                    <button mat-flat-button
                            color="case-presentation"
                            (click)="notNow()">
                        Not now
                    </button>
                </div>

                <div class="col-6" style="text-align: right">
                    <button mat-flat-button
                            color="case-presentation"
                            [disabled]="(selectedCustomizePlanTab == 'officeFinance' && !selectedTreatmentPlan.paymentPlanContractId) || (selectedCustomizePlanTab != 'officeFinance' && !selectedTreatmentPlan.pifContractId)"
                            (click)="signAndPay()">
                        Sign Now
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #editDownMonthsDialog>
    <h4 matDialogTitle>Set Custom Values</h4>
    <mat-dialog-content>
        <div>
            <mat-form-field>
                <mat-label>down amount</mat-label>
                <input matInput
                       type="number"
                       [(ngModel)]="editDown">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>number of months</mat-label>
                <input matInput
                       type="number"
                       [(ngModel)]="editMonths">
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button 
                (click)="dialogRef.close(null)" 
                style="border: none;">
            Cancel
        </button>
        <button mat-button 
                (click)="dialogRef.close(true)" 
                style="border: none; margin-left: 40px;">
            Save
        </button>
    </mat-dialog-actions>
</ng-template>
