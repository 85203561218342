import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';

import emailMask from 'text-mask-addons/dist/emailMask';
import { Router } from '@angular/router';
import { Store, ActionsSubject } from '@ngrx/store';
import { AuthStoreActions, AuthStoreSelectors, LocationsStoreActions, LocationsStoreSelectors, LocationStoreEntity, RootStoreState } from '../../../root-store';
import { CreateNewPatientAppointmentDto, NewPatientClient, NewPatientUrlDto, PatientClient, RelationshipDto, RelationshipTypeEnum, ScheduleGridDto, ScheduleGridItemDto, FileParameter } from '../../../shared/services/api.service';
import { debounceTime, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AppointmentDetail } from '../../../shared/models/appointment/AppointmentDetail';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { fromEvent } from 'rxjs';
import { ofType } from '@ngrx/effects';

@Component({
    selector: 'new-patient',
    templateUrl: './new-patient.component.html',
    styleUrls: ['./new-patient.component.css']
})
export class NewPatientComponent implements OnInit, OnDestroy {
    recaptchaSiteKey: string = '6LcnU3EfAAAAAKeewez0hb84y23WHNK0PvYC_2EC';
    private _destroy$: Subject<boolean> = new Subject<boolean>();
    locations$ = this._store$.select(LocationsStoreSelectors.selectActiveLocations)
        .pipe(
            takeUntil(this._destroy$),
            map((locations) => {
                let location = locations[0];
                if (location)
                    this.selectLocation(location);

                return locations;
            })
        );
    locationIsLoading$ = this._store$.select(LocationsStoreSelectors.selectLocationsIsLoading);
    headerText$ = this._store$.select(AuthStoreSelectors.getHeaderText);
    logoLocationUrl$ = this._store$.select(AuthStoreSelectors.getLogoLocationUrl);

    emailMask = emailMask;
    phonemask: any = ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    selectedDate: Date;
    minDate: Date = new Date();
    maxDate: Date = null;
    readonly today = moment().toDate();

    blockIsLoading: boolean = false
    isWorking: boolean = false;

    selectedLocation: LocationStoreEntity;
    schedules: ScheduleGridItemDto[];
    selectedSchedule: ScheduleGridItemDto;
    newPatientAppointment: FormGroup;
    readonly relationshipTypes: any = _.toArray(RelationshipTypeEnum);
    @ViewChild("HearAboutInput", { static: false }) HearAboutInput: ElementRef;
    hearAboutUs: any[] = [
        {name: "Social media"},
        {name: "Friend"},
        {name: "Family"},
        {name: "Internet search"},
        {name: "Dental referral"},
        {name: "Other"}
    ];
    hearAboutUsFilter: any[] =[];
    settingsNewPatientUrlDto: NewPatientUrlDto;
    insuranceFiles = [];
    isCreatedPatient:boolean = false;

    constructor(
        private _router: Router,
        private _store$: Store<RootStoreState.State>,
        private _newPatientClient: NewPatientClient,
        private _snackbar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private _patientClient: PatientClient,
        private reCaptchaV3Service: ReCaptchaV3Service,
        private _actions$: ActionsSubject
    ) { }

    ngOnDestroy() {
        this._destroy$.next(true);
    }

    ngOnInit(): void {
        this.initializeNewPatientAppointment();
        this._store$.dispatch(LocationsStoreActions.LoadRequest({}));

        this._actions$
            .pipe(ofType(AuthStoreActions.NewPatientOptionSuccess), take(1), takeUntil(this._destroy$))
            .subscribe((result) => {
              this.settingsNewPatientUrlDto = result.newPatientUrlOption.settings;
              this.newPatientAppointment.patchValue({
                referringComments: this.settingsNewPatientUrlDto.marketingType
              })
          });

        
    }

    ngAfterViewInit() {
		setTimeout(() => {
			this.searchHearAbout();
		}, 500);
	}

    reInitialize(): void {
        this.newPatientAppointment.reset();
        this.selectedSchedule = null;
        this.selectedLocation = null;
    }

    initalizeDateSelection(): void {
        this.onSelectDate(new Date());
    }

    navigateToAppointmentDetail() {
        this._router.navigate(['/appointment-details']);
    }

    onSelectDate(event: Date) {
        if (!event || this.blockIsLoading) return null;

        this.blockIsLoading = true;
        this.schedules = null;
        this.selectedDate = new Date(event.toDateString());
        this.toggleDateSelection(false);
        this.selectedSchedule = null;

        this._newPatientClient.newPatient_GetAppointmentsAvailable(
            this.selectedLocation.id,
            this.selectedDate)
            .pipe(
                take(1),
                takeUntil(this._destroy$))
            .subscribe(
                (result) => {
                    let scheduleGrid: ScheduleGridDto = _.find(result, (r: ScheduleGridDto) => {
                        return r.date.getUTCDate() === this.selectedDate.getDate() &&
                            r.date.getUTCMonth() === this.selectedDate.getMonth() &&
                            r.date.getUTCFullYear() === this.selectedDate.getFullYear();
                    });
                    this.schedules = scheduleGrid ? scheduleGrid.items : null;
                    this.toggleDateSelection(true);
                    this.blockIsLoading = false;
                },
                (err) => {
                    this.blockIsLoading = false;
                    this.toggleDateSelection(true);
                    this._snackbar.open('An error has occured', 'OK', { duration: 3000 });
                }
            );
    }

    private toggleDateSelection(enable: boolean): void {
        if (enable)
            this.maxDate = null;
        else {
            this.maxDate = new Date();
            this.maxDate.setDate(this.minDate.getDate() - 1);
        }
    }

    myDateFilter = (d: Date): boolean => {
        const day = d.getDay();
        return day > -1;
    }

    selectLocation(location) {
        this.selectedLocation = location;
        this.initalizeDateSelection();
    }

    toggleSchedule(schedule: ScheduleGridItemDto): void {
        if (!schedule || this.blockIsLoading) return null;

        let toLock: boolean = !this.selectedSchedule || this.selectedSchedule.id != schedule.id;
        this.selectedSchedule = null;
        this.blockIsLoading = true;
        this.toggleDateSelection(false);

        if (toLock)
            this._newPatientClient.newPatient_PostBlockLock(
                this.selectedLocation.id,
                schedule.id)
                .pipe(
                    take(1),
                    takeUntil(this._destroy$))
                .subscribe(
                    () => {
                        this.selectedSchedule = schedule;
                        this.toggleDateSelection(true);
                        this.blockIsLoading = false;
                    },
                    (err) => {
                        this.selectedSchedule = null;
                        this.blockIsLoading = false;
                        this.toggleDateSelection(true);
                        this._snackbar.open(err.error, 'OK', { duration: 3000 });
                    }
                );
        else
            this._newPatientClient.newPatient_PostBlockUnlock(
                this.selectedLocation.id,
                schedule.id)
                .pipe(
                    take(1),
                    takeUntil(this._destroy$))
                .subscribe(
                    () => {
                        this.selectedSchedule = null;
                        this.toggleDateSelection(true);
                        this.blockIsLoading = false;
                    },
                    (err) => {
                        this.selectedSchedule = null;
                        this.blockIsLoading = false;
                        this.toggleDateSelection(true);
                        this._snackbar.open(err.error, 'OK', { duration: 3000 });
                    }
                );
    }

    initializeNewPatientAppointment(): void {
        this.newPatientAppointment = this._formBuilder.group({
            patientFirstName: ['', Validators.required],
            patientLastName: ['', Validators.required],
            patientDOB: [null],
            isPatientResponsible: [true],
            contactFirstName: [null],
            contactLastName: [null],
            contactRelationshipType: [null],
            emailAddress: [null, [Validators.email]],
            mobilePhone: [null, [Validators.pattern('^\\D?(\\d{3})\\D?\\D?(\\d{3})\\D?(\\d{4})$')]],
            referringComments: [''],
            concerns: [''],
            files: []
        });
    }

    birthDateChanged(date: any) {
        this.newPatientAppointment.patchValue({
            patientDOB: date
        });
    }

    saveNewPatientAppointment(): void {
        if (!this.newPatientAppointment.valid) {
            this._snackbar.open('Please fill out requried fields.', 'OK', { duration: 3000 });
            return;
        }

        if (!this.selectedLocation) {
            this._snackbar.open('Please select location.', 'OK', { duration: 3000 });
            return;
        }

        let newPatientAppointmentValue: any = this.newPatientAppointment.value;

        if (!newPatientAppointmentValue.isPatientResponsible) {
            if (!newPatientAppointmentValue.contactRelationshipType) {
                this._snackbar.open('Please select Relationship to patient.', 'OK', { duration: 3000 });
                return;
            }

            if (!newPatientAppointmentValue.contactFirstName) {
                this._snackbar.open('Please input Responsible First Name .', 'OK', { duration: 3000 });
                return;
            }

            if (!newPatientAppointmentValue.contactLastName) {
                this._snackbar.open('Please input Responsible Last Name .', 'OK', { duration: 3000 });
                return;
            }
        }

        let createNewPatientAppointmentDto: CreateNewPatientAppointmentDto = new CreateNewPatientAppointmentDto({
            procedureId: 0,
            patientStatusId: 0,
            patientFirstName: newPatientAppointmentValue.patientFirstName,
            patientLastName: newPatientAppointmentValue.patientLastName,
            patientDOB: newPatientAppointmentValue.patientDOB,
            isPatientResponsible: newPatientAppointmentValue.isPatientResponsible,
            contactFirstName: newPatientAppointmentValue.isPatientResponsible ? null : newPatientAppointmentValue.contactFirstName,
            contactLastName: newPatientAppointmentValue.isPatientResponsible ? null : newPatientAppointmentValue.contactLastName,
            contactRelationshipType: newPatientAppointmentValue.isPatientResponsible ? null : newPatientAppointmentValue.contactRelationshipType,
            emailAddress: newPatientAppointmentValue.emailAddress,
            mobilePhone: newPatientAppointmentValue.mobilePhone ? newPatientAppointmentValue.mobilePhone.replace(/[^\d]/g, '') : newPatientAppointmentValue.mobilePhone,
            referringComments: newPatientAppointmentValue.referringComments,
            concerns: newPatientAppointmentValue.concerns
        });

        this.toggleDateSelection(false);
        this.isWorking = true;

        let hangOnMessage = this._snackbar.open(
            "Hang on, I'm adding you to our schedule.",
            null,
            {
                horizontalPosition: 'end',
                verticalPosition: 'top'
            });

        let appointmentDetail: AppointmentDetail = {
            patientId: null,
            patientFirstName: newPatientAppointmentValue.patientFirstName,
            patientLastName: newPatientAppointmentValue.patientLastName,
            patientDOB: newPatientAppointmentValue.patientDOB,
            isPatientResponsible: newPatientAppointmentValue.isPatientResponsible,
            contactFirstName: newPatientAppointmentValue.isPatientResponsible ? null : newPatientAppointmentValue.contactFirstName,
            contactLastName: newPatientAppointmentValue.isPatientResponsible ? null : newPatientAppointmentValue.contactLastName,
            contactRelationshipType: newPatientAppointmentValue.isPatientResponsible ? null : newPatientAppointmentValue.contactRelationshipType,
            startTime: this.selectedSchedule ? this.selectedSchedule.startTime : null,
            locationName: this.selectedLocation.name,
            locationAddress: this.getLocationAddress(this.selectedLocation),
            locationTimeZone: this.selectedLocation.ianaTimeZone,
            patientContactId: null,
            patientRelationshipId: null,
            patientRelationshipEtag: null,
            contactId: null,
            contactRelationshipId: null,
            contactRelationshipEtag: null,
        };

        

        this._store$.select(AuthStoreSelectors.getCommunicationDeskId)
            .pipe(
                take(1),
                takeUntil(this._destroy$)
            )
            .subscribe((communicationDeskId) => {
                createNewPatientAppointmentDto.communicationDeskId = communicationDeskId;
                this.reCaptchaV3Service.execute(this.recaptchaSiteKey, 'homepage', (token) => {
                    createNewPatientAppointmentDto.recaptchaToken = token;
                    this._newPatientClient.newPatient_PutBlock(
                        this.selectedLocation.id,
                        this.selectedSchedule ? this.selectedSchedule.id : 0,
                        createNewPatientAppointmentDto)
                        .pipe(
                            switchMap(patient => {
                                appointmentDetail.patientId = patient.id;
                                return this._patientClient.patient_GetPatientRelationships(patient.id);
                            }),
                            take(1),
                            takeUntil(this._destroy$))
                        .subscribe(
                            (res: RelationshipDto[]) => {
                                let patientRelationship: RelationshipDto = _.find(res, { 'relationshipType': RelationshipTypeEnum.Patient });
                                let contactRelationship: RelationshipDto = _.find(res, (r) => r.relationshipType != RelationshipTypeEnum.Patient);
                                if (patientRelationship) {
                                    appointmentDetail.patientContactId = patientRelationship.contactId;
                                    appointmentDetail.patientRelationshipId = patientRelationship.id;
                                    appointmentDetail.patientRelationshipEtag = patientRelationship.eTag;
                                }
                                if (contactRelationship) {
                                    appointmentDetail.contactId = contactRelationship.contactId;
                                    appointmentDetail.contactRelationshipId = contactRelationship.id;
                                    appointmentDetail.contactRelationshipEtag = contactRelationship.eTag;
                                }

                                this._store$.dispatch(AuthStoreActions.SetAppointmentDetail({ appointmentDetail: appointmentDetail }));
                                
                                if(this.settingsNewPatientUrlDto.disableInsurance == false){
                                    this._snackbar.open('New patient appointment successful.', 'OK', { duration: 3000 });
                                    this.toggleDateSelection(true);
                                    hangOnMessage.dismiss();
                                    this.isWorking = false;
                                    this.reInitialize();
                                    this.navigateToAppointmentDetail();
                                } else {
                                    let patientInsurances: FileParameter[] = this.getInsuranceFiles(this.newPatientAppointment.value);
                                    
                                    if( patientInsurances.length > 0){
                                        this._patientClient.patient_PostPatientInsuranceFiles(appointmentDetail.patientId, patientInsurances).subscribe(resp => {
                                            this._snackbar.open('New patient appointment successful.', 'OK', { duration: 3000 });
                                            this.toggleDateSelection(true);
                                            hangOnMessage.dismiss();
                                            this.isCreatedPatient = true;
                                            this.newPatientAppointment.reset();
                                            this.newPatientAppointment.patchValue({
                                                isPatientResponsible: true
                                            })
                                            this.insuranceFiles = [];
                                            this.isWorking = false;
                                        },
                                        err => {
                                            hangOnMessage.dismiss();
                                            this.isWorking = false;
                                            this._snackbar.open(err.message, 'OK', { duration: 3000 });
                                        })
                                    } else {
                                        this.isCreatedPatient = true;
                                        this.newPatientAppointment.reset();
                                        this.newPatientAppointment.patchValue({
                                            isPatientResponsible: true
                                        })
                                    }
                                }
                                
                            },
                            (err) => {
                                hangOnMessage.dismiss();
                                this.isWorking = false;
                                this.toggleDateSelection(true);
                                this._snackbar.open("An error has occured.", 'OK', { duration: 3000 });
                            }
                        );
                }, {
                    useGlobalDomain: false
                });
            });
    }

    private getLocationAddress(location: LocationStoreEntity): string {
        if (!location || !location.address) return null;

        let locationAddress = this.selectedLocation.address;
        return `${locationAddress.line1}, ${locationAddress.line2} ${locationAddress.city} ${locationAddress.state} ${locationAddress.zip}`;
    }

    searchHearAbout() {
        this.hearAboutUsFilter = this.hearAboutUs;
		fromEvent(this.HearAboutInput.nativeElement, "keyup").pipe(
			debounceTime(500),
			filter((e: KeyboardEvent) => e.keyCode !== 40),
			filter((e: KeyboardEvent) => e.keyCode !== 38),
			map((userInput: any) => userInput.target.value)).subscribe(res => {

				let searchkeyword: any = res.toLowerCase();
				if (searchkeyword.length > 0) {
					this.hearAboutUsFilter = this.hearAboutUs.filter(option => option.name.toLowerCase().includes(searchkeyword));
					if (this.hearAboutUsFilter.length == 0) {
						this.hearAboutUsFilter = this.hearAboutUs;
					}

				} else {
					this.hearAboutUsFilter = this.hearAboutUs;
				}
			})
	}

    checkbeforeSubmit(){
        if(this.newPatientAppointment.valid){
            if(this.newPatientAppointment.value.emailAddress || this.newPatientAppointment.value.mobilePhone){
                this.saveNewPatientAppointment();
            } else {
                this._snackbar.open('A contact method (phone or email) is required.', 'OK', { duration: 3000 });
            }
        }
    }

    insuranceFileChanged(event): void {
        this.newPatientAppointment.get('files').setValue([...event]);
        this.insuranceFiles = this.newPatientAppointment.value.files;
    }

    private getInsuranceFiles(insuranceInformation: any): FileParameter[] {
        let files: FileParameter[] = [];
        if (insuranceInformation.files && insuranceInformation.files.length > 0) {
            _.each(insuranceInformation.files, (f: File, i: number) => {
                const data = new Blob([f], { type: f.type })
                files.push({
                    data,
                    fileName: f.name.replace(new RegExp('/', 'g'), '_'),
                });
            });

        }

        return files;
    }

    clearForm(){
        this.isCreatedPatient = false;
        this.newPatientAppointment.reset();
        this.newPatientAppointment.patchValue({
            isPatientResponsible: true
        })
    }

    getAttachedInsuranceFiles(): File[] {
        return this.newPatientAppointment.get('files').value as File[];
    }

    removeAttachedInsuranceFile(i: number): void {
        let attachments = this.getAttachedInsuranceFiles();
        if (!attachments || attachments.length < 1) return;
        if (i > -1) {
            attachments.splice(i, 1);
            this.insuranceFiles.splice(i, 1);
        }
        this.newPatientAppointment.get('files').setValue(attachments);
    }
}
