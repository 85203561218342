import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import * as moment from 'moment';
import { APPOINTMENT_FEATURE_KEY } from './featurekey';
import { featureAdapter, AppointmentStoreEntity, State } from './state';

export const selectAppointmentsState: MemoizedSelector<object, State> = createFeatureSelector<State>(APPOINTMENT_FEATURE_KEY);

export const getSelectedAppointmentId = createSelector(selectAppointmentsState, (state) => state.selectedAppointmentId);

const { selectAll, selectIds, selectEntities } = featureAdapter.getSelectors(selectAppointmentsState);
export const selectAppointmentIds = selectIds;
export const selectAllAppointments = selectAll;
export const selectAllAppointmentsEntities = selectEntities;

export const getSelectedAppointment = createSelector(
  selectAppointmentsState,
  selectAllAppointments,
  (state, allAppointments: AppointmentStoreEntity[]) =>
    allAppointments ? allAppointments.find((rp) => rp.id === state.selectedAppointmentId) : null
);

export const selectAppointmentById = (id: number) =>
  createSelector(selectAllAppointments, (allAppointments: AppointmentStoreEntity[]) =>
    allAppointments ? allAppointments.find((rp) => rp.id === id) : null
  );

export const selectAppointmentsByDob = (dob: Date) =>
  createSelector(selectAllAppointments, (allAppointments: AppointmentStoreEntity[]) =>
    allAppointments ? allAppointments.filter((appointment) => moment(appointment.dob).tz('utc').format('MM/DD/YYYY') === moment(dob).tz('utc').format('MM/DD/YYYY')) : null
  );

export const selectAppointmentsError: MemoizedSelector<object, any> = createSelector(selectAppointmentsState, (state) => state.error);

export const selectAppointmentsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectAppointmentsState, (state: State) => state.isLoading);
