import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateModule } from '../../../pipe/moment-date/moment-date.module';
import { PatientFormComponent } from './patient-form.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SignatureDialogModule } from '../../../shared/signature-dialog/signature-dialog.module';
import { OrderModule } from '../../../pipe/order/order.module';
import { SafeHtmlModule } from '../../../pipe/safe-html/safe-html.module';
import { MatRadioModule } from '@angular/material/radio';
import { ngfModule } from 'angular-file';
import { MatListModule } from '@angular/material/list';

@NgModule({
	declarations: [PatientFormComponent],
	exports: [PatientFormComponent],
	imports: [
		CommonModule,
		FormsModule,
		MomentDateModule,
		OrderModule,
		SafeHtmlModule,
		MatProgressBarModule,
		MatButtonModule,
		MatIconModule,
		MatFormFieldModule,
		MatInputModule,
		ReactiveFormsModule,
		MatCheckboxModule,
		MatDatepickerModule,
		SignatureDialogModule,
		MatRadioModule,
		ngfModule,
		MatListModule
	]
})
export class PatientFormModule { }
