<div class="component-container" style="max-width: 768px; margin: auto;">

    <div class="location-name">{{selectedLocation?.name}}</div>
    <!-- <div class="location-addr">
        <div>{{selectedLocation?.address?.line1}}</div>
        <div *ngIf="selectedLocation && selectedLocation.address && selectedLocation.address.line2 != ''">{{selectedLocation?.line2}}</div>
        <div>{{selectedLocation?.address.city}}, {{selectedLocation?.address.state}} {{selectedLocation?.address.zip}}</div>
    </div> -->


    <div class="patient-name">{{patient?.firstName}} {{patient?.lastName}}</div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12 bottomspace">

                <!-- <div class="section-container" *ngIf="!isEnabled">
                    <div class="booking-info">
                        <div *ngIf="selectedLocation?.phoneNumber">
                            This link is no longer valid.<br/>
                            <br/>
                            Please contact the office at {{selectedLocation?.phoneNumber}} to schedule your appointment.
                        </div>
                        <div *ngIf="!selectedLocation?.phoneNumber">
                            This link is no longer valid.<br/>
                            <br/>
                            Please contact the office to schedule your appointment.
                        </div>
                    </div>
                </div> -->

                <div class="section-container">
                    <div class="booking-info">
                        <div *ngIf="locations.length > 1">Select office location and day.<br> Then select the time for your next appointment</div>
                        <div *ngIf="locations.length == 1">Select day.<br> Then select the time for your next appointment</div>
                    </div>

                    <div class="booking-info" style="display: flex; justify-content: center;">
                        <div>
                            <ng-container *ngFor="let location of locations">
                                <div style="display: flex; margin-bottom: 10px; cursor: pointer;" (click)="selectLocation(location)">
                                    <div class="check-box-box">
                                        <fa-icon style="color: #3F91CD;" [icon]="faCheck" *ngIf="location.isChecked"></fa-icon>
                                    </div>
                                    <div>
                                        {{location.address.line1}} 
                                        <span *ngIf="location.address.line2">, </span>{{location.address.line2}}
                                        <span *ngIf="location.address.city">, </span>{{location.address.city}}
                                        {{location.address.state}} {{location.address.zip}}
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        
                        
                    </div>

                    
                    <mat-progress-bar mode="indeterminate" *ngIf="blockIsLoading"></mat-progress-bar>
                    <ng-container *ngIf="selectedLocation">
                        <div class="calender-timeslot-wrapper">
                            <div class="calender-timeslot-wrapper-cont">
                                <div class="calender-cont">
                                    <div class="inline-datepicker">
                                        <mat-calendar [selected]="selectedDate" [minDate]="minDate" [maxDate]="maxDate"
                                            (selectedChange)="onSelectDate($event)" [dateFilter]="myDateFilter" class="calendar-schedule">
                                        </mat-calendar>
                                    </div>
                                </div>
                                <div class="warning-info">Available times are offered by the type of appointment needed and within the time frame
                                    specified by the doctor. Call the office if you need different accommodations.</div>
                            </div>

                            <div class="calender-timeslot-wrapper-cont">
                                <div class="timeslot-wrapper">
                                    <div class="timeslot-label" *ngIf="schedules && schedules.length > 0">Available times for this day- this office</div>
                                    <div class="timeslot-label no-appointment" *ngIf="schedules && schedules.length == 0">Sorry no appointments available for this day.</div>
                                    <div class="container">
                                        <ng-container *ngFor="let schedule of schedules; let index = index">
                                            <ng-container *ngIf="index == 0 || index % 3 == 0">
                                                <div class="row" style="margin-bottom: 15px">
                                                    <div class="col-12"
                                                        *ngIf="schedules[index]">
                                                        <div class="timeslot-selector new-patient-url-button"
                                                            (click)="toggleSchedule(schedules[index])"
                                                            [ngClass]="{'disabled': isWorking || blockIsLoading || (selectedSchedule && schedules[index].id == selectedSchedule.id)}">
                                                            {{schedules[index].startTime | momentDate: 'MM/dd/yyyy EEEE' : selectedLocation?.ianaTimeZone}} @ {{schedules[index].startTime | momentDate: 'hh:mm a' : selectedLocation?.ianaTimeZone}}
                                                        </div>
                                                    </div>
                                                    <div class="col-12"
                                                        *ngIf="schedules[index + 1]">
                                                        <div class="timeslot-selector new-patient-url-button"
                                                            (click)="toggleSchedule(schedules[index + 1])"
                                                            [ngClass]="{'disabled': isWorking || blockIsLoading || (selectedSchedule && schedules[index + 1].id == selectedSchedule.id)}">
                                                            {{schedules[index + 1].startTime | momentDate: 'MM/dd/yyyy EEEE' : selectedLocation?.ianaTimeZone}} @ {{schedules[index + 1].startTime | momentDate: 'hh:mm a' : selectedLocation?.ianaTimeZone}}
                                                        </div>
                                                    </div>
                                                    <div class="col-12"
                                                        *ngIf="schedules[index + 2]">
                                                        <div class="timeslot-selector new-patient-url-button"
                                                            (click)="toggleSchedule(schedules[index + 2])"
                                                            [ngClass]="{'disabled': isWorking || blockIsLoading || (selectedSchedule && schedules[index + 2].id == selectedSchedule.id)}">
                                                            {{schedules[index + 2].startTime | momentDate: 'MM/dd/yyyy EEEE' : selectedLocation?.ianaTimeZone}} @ {{schedules[index + 2].startTime | momentDate: 'hh:mm a' : selectedLocation?.ianaTimeZone}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div *ngIf="selectedSchedule"
                             class="hold-information">
                          I'm going to hold {{selectedSchedule.startTime | momentDate: 'hh:mm a' : selectedLocation?.ianaTimeZone}} on the {{selectedSchedule.startTime | momentOrdinalDate: selectedLocation?.ianaTimeZone}} while you enter your name and contact information.
                        </div> -->
                    </ng-container>
                </div>
            </div>

            <!-- <div class="col-md-12 col-sm-12 col-xs-12">
                <mat-progress-bar mode="indeterminate" *ngIf="isWorking"></mat-progress-bar>
                <form (ngSubmit)="saveAppointment()">
                    <div class="section-container colored-bg">
                        <button mat-button
                                [disabled]="!this.selectedLocation || !selectedSchedule"
                                class="appintment-btn new-patient-url-button"
                                type="submit">
                            Please schedule my appointment
                        </button>
                    </div>
                </form>
            </div> -->
        </div>
    </div>
</div>

<ng-template #confirmingDialog>
    <mat-dialog-content>
        <div>You have chosen {{selectedSchedule.startTime | momentDate: 'MM/dd/yyyy' : selectedLocation?.ianaTimeZone}} at {{selectedSchedule.startTime | momentDate: 'hh:mm a' : selectedLocation?.ianaTimeZone}}.</div>
         <div>Is this correct?</div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">

        <button mat-flat-button
                class="stroked-cancel-btn"
                (click)="dialogRef.close(null)"
                >
            No
        </button>

        <button mat-stroked-button
                class="stroked-ok-btn"
                (click)="dialogRef.close(true)"
                >
            Yes
        </button>
    </mat-dialog-actions>
</ng-template>