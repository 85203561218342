import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State, featureAdapter } from "./state";
import * as LocationsStoreActions from "./actions";

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(
    LocationsStoreActions.LoadRequest,
    (state, action) => ({ ...state, isLoading: true, error: null, page: action.page || state.page, pageSize: action.pageSize || state.pageSize }),
  ),
  on(LocationsStoreActions.LoadSuccess, (state, action) => {
    featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.locations, { ...state, isLoading: false, error: null })
  }),
  on(LocationsStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //SELECT
  on(LocationsStoreActions.SelectRequest, (state, action) => ({...state, isLoading: true, error: null, selectedLocationId: null})),
  on(LocationsStoreActions.SelectSuccess, (state, action) => ({...state, isLoading: false, error: null, selectedLocationId: action.location.id})),
  on(LocationsStoreActions.SelectFailure, (state, action) => ({...state, isLoading: false, error: action.error}))
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
