import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { AppointmentDetail } from '../../shared/models/appointment/AppointmentDetail';
import { TokenDto } from '../../shared/services/api.service';

export const featureAdapter = createEntityAdapter<TokenDto>({
    selectId: (token) => token.id,
    sortComparer: (a, b) => a.id.localeCompare(b.id)
});

export interface State extends EntityState<TokenDto> {
    selectedTokenId?: string;
    isLoading?: boolean;
    error?: any;
    tenantKey?: string;
    tenantName?: string;
    maskedTenantKey?: string;
    primaryColor?: string;
    secondaryColor?: string;
    headerText?: string;
    footerText?: string;
    logoLocationUrl?: string;
    appointmentDetail?: AppointmentDetail;
    communicationDeskId?: number;
    location?: any;
    settings?: any;
}

export const initialState: State = featureAdapter.getInitialState({
    selectedTokenId: null,
    isLoading: false,
    error: null,
    tenantKey: null,
    tenantName: null,
    maskedTenantKey: null,
    primaryColor: null,
    secondaryColor: null,
    headerText: null,
    footerText: null,
    logoLocationUrl: null,
    appointmentDetail: null,
    communicationDeskId: null,
    location: null,
    settings: null
});
