import { Action, createReducer, on } from "@ngrx/store";
import * as AppointmentStoreActions from "./actions";
import { featureAdapter, initialState, State } from "./state";

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(AppointmentStoreActions.LoadRequest, (state, action) => ({ ...state, isLoading: true, error: null, locationId: action.locationId })),
  on(AppointmentStoreActions.LoadSuccess, (state, action) => {
    const s = featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.appointments, { ...s, isLoading: false, error: null })
  }),
  on(AppointmentStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
  //SELECT
  on(AppointmentStoreActions.SelectRequest, (state, action) => ({ ...state, isLoading: true, error: null, selectedAppointmentId: null  })),
  on(AppointmentStoreActions.SelectSuccess, (state, action) => ({ ...state, isLoading: false, error: null, selectedAppointmentId: action.appointment && action.appointment.id })),
  on(AppointmentStoreActions.SelectFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
