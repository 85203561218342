import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import * as moment from 'moment-timezone';

@Pipe({
    name: 'momentOrdinalDate'
})
export class MomentOrdinalDatePipe implements PipeTransform {
    transform(
        value: string | Date,
        timezone: string = 'UTC'
    ): string {
        if (!timezone) timezone = 'UTC';
        const timezoneOffset = moment(value).tz(timezone).format('Z');
        let dayOfMonth = Number(formatDate(value, "dd", "en-US", timezoneOffset));

        return `${dayOfMonth}${this.nth(dayOfMonth)}`;
    }

    nth(d: number) {
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    }
}
