<new-patient *ngIf="portalType == portalTypeEnum.NewPatientUrl"></new-patient>

<patient-check-in *ngIf="portalType == portalTypeEnum.PatientCheckInUrl"></patient-check-in>

<patient-case-presentation *ngIf="portalType == portalTypeEnum.PatientCasePresentationUrl"></patient-case-presentation>

<patient-reschedule *ngIf="portalType == portalTypeEnum.PatientRescheduleUrl"></patient-reschedule>

<patient-schedule *ngIf="portalType == portalTypeEnum.PatientSelfScheduleUrl"></patient-schedule>

<patient-marketing *ngIf="portalType == portalTypeEnum.PatientMarketingUrl"></patient-marketing>

<patient-confirm *ngIf="portalType == portalTypeEnum.PatientConfirmUrl"></patient-confirm>

<patient-form *ngIf="portalType == portalTypeEnum.PatientFormUrl"></patient-form>

<app-secure-email *ngIf="portalType == portalTypeEnum.SecureMessageUrl"></app-secure-email>

<patient-payment *ngIf="portalType == portalTypeEnum.PatientPaymentUrl"></patient-payment>

<marketing *ngIf="portalType == portalTypeEnum.MarketingUrl"></marketing>