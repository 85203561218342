import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { of, Subject } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { AuthenticationStoreEffectsService, AuthStoreActions, RootStoreState } from 'src/app/root-store';
import { AuthenticateTokenModel, ErrorDetails, PinRequestModel, UserClient } from 'src/app/shared/services/api.service';
import { AuthenticationService } from '../../shared/services/auth';

@UntilDestroy()
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    isWorkingCount: number = 0;
    verificationMethod: string;

    patientPinRequestFormGroup: FormGroup = this._fb.group({
        method: [null, [Validators.required]],
        email: [null, [Validators.email]],
        phone: [null, [Validators.pattern(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm)]],
    });
    patientPinRequestError: string;
    showPinRequest: boolean = true;

    patientLoginFormGroup: FormGroup = this._fb.group({
        email: this._fb.control({ value: null, disabled: true }),
        phone: this._fb.control({ value: null, disabled: true }),
        pin: [null, [Validators.required, Validators.pattern(/^[0-9]{6}$/)]],
    });
    patientLoginError: string;
    showLogin: boolean = false;
    multipleEmail: boolean = false;
    emails: string[];
    private _destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private _store$: Store<RootStoreState.State>,
        private _fb: FormBuilder,
        private _userClient: UserClient,
        private _authStoreEffects: AuthenticationStoreEffectsService,
        private _router: Router,
        private _snackBar: MatSnackBar,
        private _authService: AuthenticationService,
    ) {
    }

    ngOnInit() { }

    ngOnDestroy() {
        this._destroy$.next(true);
    }

    requestPin() {
        this.patientPinRequestError = null;
        this.isWorkingCount += 1;
        const model = new PinRequestModel();
        switch (this.patientPinRequestFormGroup.get('method').value) {
            case 'phone':
                model.phone = this.patientPinRequestFormGroup.get('phone').value;
                break;
            case 'email':
                model.email = this.patientPinRequestFormGroup.get('email').value;
                break;
        }
        this._userClient
            .user_PostPin(model)
            .pipe(
                take(1),
                takeUntil(this._destroy$),
                catchError((err: ErrorDetails) => {
                    switch (this.patientPinRequestFormGroup.get('method').value) {
                        case 'phone':
                            this.openSnackBar("The phone entered is not registered.", "OK");
                            break;
                        case 'email':
                            this.openSnackBar("The email entered is not registered.  Please enter the email address that the email was sent to.", "OK");
                            break;
                    }
                    this.isWorkingCount--;
                    this.patientPinRequestError = err.message;
                    return of({error: err});
                })
            )
            .subscribe((result) => {
                if (!result || !result.error) {
                    this.patientLoginFormGroup.patchValue(this.patientPinRequestFormGroup.value);
                    this.showPinRequest = false;
                    this.showLogin = true;
                }
                
                this.isWorkingCount -= 1;
            });
    }

    login() {
        this.isWorkingCount += 1;
        this.patientLoginError = null;
        const model = new AuthenticateTokenModel({ pin: this.patientLoginFormGroup.get('pin').value });
        switch (this.patientPinRequestFormGroup.get('method').value) {
            case 'phone':
                model.phone = this.patientPinRequestFormGroup.get('phone').value;
            case 'email':
                model.email = this.patientPinRequestFormGroup.get('email').value;
        }

        this._authStoreEffects.loginRequestEffect$.pipe(take(1)).subscribe((result) => {
            if (result.type == AuthStoreActions.LoginSuccess.type) {
                let isQuote = this._authService.getIsQuote();
                if (isQuote)
                    this._router.navigate(['/contracts', 'quote']);
                else
                    this._router.navigate(['/contracts', 'sign']);
            } else {
                this.patientLoginError = "There was an error while trying to validate. Please ensure you have entered a valid phone or email and entered your pin correctly.";
            }
            this.isWorkingCount -= 1;
        });
        this._store$.dispatch(AuthStoreActions.LoginRequest({ authModel: model }));
    }

    goBack() {
        this.showPinRequest = true;
        this.showLogin = false;
    }

    //logout() {
    //  this._store$.dispatch(AuthStoreActions.LogoutRequest({ returnUrl: '/patient/login?tenant=test' }));
    //}

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 3000,
        });
    }
}
