import { createReducer, on } from '@ngrx/store';
import * as AuthStoreActions from './actions';
import { featureAdapter, initialState, State } from './state';

const reducer = createReducer(
    initialState,
    on(AuthStoreActions.AuthenticateRequest, (state, action) => ({ ...state, isLoading: true })),
    on(AuthStoreActions.AuthenticateFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
    on(AuthStoreActions.AuthenticateSuccess, (state, action) =>
        featureAdapter.upsertOne(action.userCredentials, {
            ...state,
            selectedTokenId: action.userCredentials.id,
            maskedTenantKey: action.maskedTenantKey
        })
    ),

    on(AuthStoreActions.AuthenticatePatientPortalBypassSuccess, (state, action) =>
        featureAdapter.upsertOne(action.userCredentials, {
            ...state,
            selectedTokenId: action.userCredentials.id,
            maskedTenantKey: action.maskedTenantKey
        })
    ),

    on(AuthStoreActions.AuthenticatePatientPortalRequest, (state, action) =>
        featureAdapter.upsertOne(action.userCredentials, {
            ...state,
            selectedTokenId: action.userCredentials.id,
            maskedTenantKey: action.maskedTenantKey
        })
    ),

    on(AuthStoreActions.SetTenant, (state, action) => ({
        ...state,
        tenantName: action.tenantName,
        tenantKey: action.tenantKey,
    })),

    on(AuthStoreActions.SetAppointmentDetail, (state, action) => ({
        ...state,
        appointmentDetail: action.appointmentDetail,
    })),

    on(AuthStoreActions.NewPatientOptionRequest, (state, action) => ({ ...state, isLoading: true })),
    on(AuthStoreActions.NewPatientOptionFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
    on(AuthStoreActions.NewPatientOptionSuccess, (state, action) => ({
        ...state,
        primaryColor: action.newPatientUrlOption.settings.primaryColorCode,
        secondaryColor: action.newPatientUrlOption.settings.secondaryColorCode,
        headerText: action.newPatientUrlOption.settings.headerText,
        footerText: action.newPatientUrlOption.settings.footerText,
        logoLocationUrl: action.newPatientUrlOption.settings.logoLocationUrl,
        communicationDeskId: action.newPatientUrlOption.settings.communicationDeskId
    })),

    on(AuthStoreActions.MarketingOptionRequest, (state, action) => ({ ...state, isLoading: true })),
    on(AuthStoreActions.MarketingOptionFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
    on(AuthStoreActions.MarketingOptionSuccess, (state, action) => ({
        ...state,
        primaryColor: action.marketingUrlOption.settings.primaryColorCode,
        secondaryColor: action.marketingUrlOption.settings.secondaryColorCode,
        headerText: action.marketingUrlOption.settings.headerText,
        footerText: action.marketingUrlOption.settings.footerText,
        logoLocationUrl: action.marketingUrlOption.settings.logoLocationUrl,
        communicationDeskId: action.marketingUrlOption.settings.communicationDeskId
    })),

    on(AuthStoreActions.PatientCheckInOptionRequest, (state, action) => ({ ...state, isLoading: true })),
    on(AuthStoreActions.PatientCheckInOptionFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
    on(AuthStoreActions.PatientCheckInOptionSuccess, (state, action) => ({
        ...state,
        location: action.patientCheckInOption.location,
        settings: action.patientCheckInOption.settings
    })),

    on(AuthStoreActions.PatientSelfScheduleOptionRequest, (state, action) => ({ ...state, isLoading: true })),
    on(AuthStoreActions.PatientSelfScheduleOptionFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
    on(AuthStoreActions.PatientSelfScheduleOptionSuccess, (state, action) => ({
        ...state,
        location: action.patientSelfScheduleOption.location,
        settings: action.patientSelfScheduleOption.settings
    })),

    on(AuthStoreActions.RefreshRequest, (state, action) => ({ ...state, isLoading: true, error: null })),
    on(AuthStoreActions.RefreshSuccess, (state, action) =>
        featureAdapter.updateOne({ id: action.id, changes: action.userCredentials }, { ...state, isLoading: false, error: null })
    ),

    on(AuthStoreActions.LoginRequest, (state, action) => ({ ...state, isLoading: true, username: action.authModel.email })),
    on(AuthStoreActions.LoginSuccess, (state, action) =>
        featureAdapter.upsertOne(action.userCredentials, { ...state, selectedTokenId: action.userCredentials.id })
    ),
    on(AuthStoreActions.LoginFailure, (state, action) => ({ ...state, isLoading: false, error: action.error })),
);

export function featureReducer(state: State | undefined, action) {
    return reducer(state, action);
}
