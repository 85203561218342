import { createReducer, on, Action } from "@ngrx/store";
import { initialState, State, featureAdapter } from "./state";
import * as InsuranceCompanyStoreActions from "./actions";

const reducer = createReducer(
  initialState,
  //LOAD PAGE
  on(
    InsuranceCompanyStoreActions.LoadRequest,
    (state, action) => ({ ...state, isLoading: true, error: null, page: action.page || state.page, pageSize: action.pageSize || state.pageSize }),
  ),
  on(InsuranceCompanyStoreActions.LoadSuccess, (state, action) => {
    featureAdapter.removeAll(state);
    return featureAdapter.addMany(action.insuranceCompanies, { ...state, isLoading: false, error: null })
  }),
  on(InsuranceCompanyStoreActions.LoadFailure, (state, action) => ({ ...state, isLoading: true, error: action.error })),
);

export function featureReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
