import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { SettingsClient } from 'src/app/shared/services/api.service';
import { State } from '../root-state';
import * as InsuranceCompanyStoreActions from './actions';

@Injectable({ providedIn: 'root' })
export class InsuranceCompanyStoreEffects {
  constructor(private store$: Store<State>, private actions$: Actions, private _settingsClient: SettingsClient) {}

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(InsuranceCompanyStoreActions.LoadRequest),
    withLatestFrom(this.store$.select((state) => state.InsuranceCompanies)),
    switchMap(([action, state]) =>
        this._settingsClient.settings_GetInsuranceCompanies().pipe(
            map((result) => InsuranceCompanyStoreActions.LoadSuccess({ insuranceCompanies: result })),
            catchError((error) => of(InsuranceCompanyStoreActions.LoadFailure({ error })))
        )
    )
  );
}
