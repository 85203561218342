import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { GravityTransactionRequest, PatientBasicDto, PatientClient, PatientPaymentClient, PatientPaymentSettingsDto, TransactionTypeEnum } from '../../../shared/services/api.service';
import { AuthenticationService } from '../../../shared/services/auth';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { RootStoreState } from '../../../root-store';
import { Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { PaymentService } from '../../../shared/services/payments/payment.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
	selector: 'patient-payment',
	templateUrl: './patient-payment.component.html',
	styleUrls: ['./patient-payment.component.css']
})
export class PatientPaymentComponent implements OnInit, OnDestroy {
	private _destroy$: Subject<boolean> = new Subject<boolean>();

    isWorking: boolean = false;

    locationId: number;
    patientId: number;
    ledgerId: number;

    paymentForm: FormGroup;
    isPaymentSaving: boolean = false;

    updatePaymentSetting:PatientPaymentSettingsDto;
    patient:PatientBasicDto;

	constructor(
		public matDialog: MatDialog,
		private _snackbar: MatSnackBar,
		private _formBuilder: FormBuilder,
        private _patientPaymentClient: PatientPaymentClient,
        private _patientClient: PatientClient,
        private _paymentService: PaymentService,
	) { }

	ngOnDestroy() {
		this._destroy$.next(true);
	}

	ngOnInit(): void {
		this.isWorking = true;

        this.paymentForm = this._formBuilder.group({
            cardHolderName: ['', Validators.required],
            cardHolderAddress: ['', Validators.required],
            cardHolderZip: ['', Validators.required]
        });

        this._patientPaymentClient.patientPayment_GetOptions().subscribe(resp => {
            this.isWorking = false;

            if (resp.location) {
                this.locationId = resp.location.id;
            }

            if (resp.patient) {
                this.patientId = resp.patient.id;
                this.patient = resp.patient;
            }

            if (resp.ledger) {
                this.ledgerId = resp.ledger.id;
            }

            if(resp.settings){
                this.updatePaymentSetting = resp.settings;
            }
        },
        err => {
            this.isWorking = false;
        });               
	}

    updatePaymentMethod(token: string) {
        if (token == "CreditSaveCard"
          || token == "AchSaveAccount") {
            var formValues = this.paymentForm.value;
            var request = GravityTransactionRequest.fromJS({
                locationId: this.locationId,
                transactionType: token == "AchSaveAccount" ? TransactionTypeEnum.AchSaveAccount : TransactionTypeEnum.CreditSaveCard,
                billingName: formValues.cardHolderName,
                billingAddress: formValues.cardHolderAddress,
                billingPostalCode: formValues.cardHolderZip
            });

            this.isPaymentSaving = true;
    
            // get token from evo api
            this._patientClient.patient_GetLedgerTerminal(this.patientId, this.ledgerId, request)
                .pipe(
                    take(1),
                    takeUntil(this._destroy$)
                )
                .subscribe(token => {
                    this.isPaymentSaving = false;
                    if (!token) {
                        this._snackbar.open('Unable to retrieve payment token', 'Close', {
                            duration: 3000,
                            panelClass: ['error-snackbar'],
                        });
                    } else {
                        // open emergePay modal
                        this._paymentService.openSaleModal(token.transactionToken ?? '');
                        this._paymentService.transactionCompleteSource$
                            .pipe(
                                take(1),
                                takeUntil(this._destroy$)
                            )
                            .subscribe((x: any) => {
                                // TODO: handle success, maybe present 'thank you'?
                            });
                    }
                }, err => {
                    this.isPaymentSaving = false;
                    this._snackbar.open('Unable to retrieve payment token', 'Close', {
                        duration: 3000,
                        panelClass: ['error-snackbar'],
                    });
                });
        } else {
            this._snackbar.open('Invalid Token.', 'Close', {
                duration: 3000,
                panelClass: ['error-snackbar'],
            });
        }
    }
}
