<div class="component-container" style="max-width: 768px; margin: auto;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <mat-progress-bar mode="indeterminate" *ngIf="(locationIsLoading$ | async)"></mat-progress-bar>
                <div class="section-container">
                  <div class="top-banner">
                    <ng-container *ngIf="(logoLocationUrl$ | async) as logoLocationUrl">
                      <img [src]="logoLocationUrl"
                           class="logo-container"
                           *ngIf="logoLocationUrl"/>
                    </ng-container>
                  </div>
                    <!-- <div class="location-address">
                        <ng-container *ngIf="selectedLocation && selectedLocation.address">
                            <div>{{selectedLocation.name}}</div>
                            <div>{{selectedLocation.address.line1}}<span *ngIf="selectedLocation.address.line1">,</span> {{selectedLocation.address.line2}} {{selectedLocation.address.city}} {{selectedLocation.address.state}} {{selectedLocation.address.zip}}</div>
                        </ng-container>
                    </div> -->

                    <div class="booking-info">
                        <ng-container *ngIf="(headerText$ | async) as headerText">
                            <div [innerHTML]="headerText"></div>
                        </ng-container>
                    </div>

                    <div class="location-dropdown-selector-label">select location that best serves you.</div>
                    <ng-container *ngFor="let location of locations">
                        <div [ngClass]="{'location-dropdown-selector': true, 'dropdown-selected': location.id == selectedLocation.id}" (click)="selectLocation(location)">
                            {{location.name}}
                        </div> 
                    </ng-container>
                </div>
            </div>

            <div class="col-md-12 col-sm-12 col-xs-12 bottomspace">

                <div class="section-container">
                    <div class="tilde-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                            id="footer-sample-full" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"
                            class="iconify iconify--mdi">
                            <path fill="currentColor"
                                d="M2 15s0-6 6-6c4 0 4.5 3.5 7.5 3.5c4 0 4-3.5 4-3.5H22s0 6-6 6c-4 0-5.5-3.5-7.5-3.5c-4 0-4 3.5-4 3.5H2">
                            </path>
                        </svg>
                    </div>
                    <mat-progress-bar mode="indeterminate" *ngIf="blockIsLoading"></mat-progress-bar>
                    <ng-container *ngIf="selectedLocation">
                        <div class="calender-cont">
                            <div class="inline-datepicker">
                                <mat-calendar [selected]="selectedDate"
                                              [minDate]="minDate"
                                              [maxDate]="maxDate"
                                              (selectedChange)="onSelectDate($event)"
                                              [dateFilter]="myDateFilter"
                                              class="calendar-schedule">
                                </mat-calendar>
                            </div>
                        </div>
                        <div class="timeslot-wrapper">
                            <div class="timeslot-label" *ngIf="schedules && schedules.length > 0">Available times for this day</div>
                            <div class="timeslot-label no-appointment" *ngIf="schedules && schedules.length == 0">Sorry no appointments available for this day.</div>
                            <div class="container">
                                <ng-container *ngFor="let schedule of schedules; let index = index">
                                    <ng-container *ngIf="index == 0 || index % 3 == 0">
                                        <div class="row" style="margin-bottom: 15px">
                                            <div class="col-4"
                                                 *ngIf="schedules[index]">
                                                <div class="timeslot-selector new-patient-url-button"
                                                     (click)="toggleSchedule(schedules[index])"
                                                     [ngClass]="{'disabled': isWorking || blockIsLoading || (selectedSchedule && schedules[index].id == selectedSchedule.id)}">
                                                    {{schedules[index].startTime | momentDate: 'hh:mm a' : selectedLocation?.ianaTimeZone}}
                                                </div>
                                            </div>
                                            <div class="col-4"
                                                 *ngIf="schedules[index + 1]">
                                                <div class="timeslot-selector new-patient-url-button"
                                                     (click)="toggleSchedule(schedules[index + 1])"
                                                     [ngClass]="{'disabled': isWorking || blockIsLoading || (selectedSchedule && schedules[index + 1].id == selectedSchedule.id)}">
                                                    {{schedules[index + 1].startTime | momentDate: 'hh:mm a' : selectedLocation?.ianaTimeZone}}
                                                </div>
                                            </div>
                                            <div class="col-4"
                                                 *ngIf="schedules[index + 2]">
                                                <div class="timeslot-selector new-patient-url-button"
                                                     (click)="toggleSchedule(schedules[index + 2])"
                                                     [ngClass]="{'disabled': isWorking || blockIsLoading || (selectedSchedule && schedules[index + 2].id == selectedSchedule.id)}">
                                                    {{schedules[index + 2].startTime | momentDate: 'hh:mm a' : selectedLocation?.ianaTimeZone}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <div *ngIf="selectedSchedule"
                             class="hold-information">
                          I'm going to hold {{selectedSchedule.startTime | momentDate: 'hh:mm a' : selectedLocation?.ianaTimeZone}} <br>on the {{selectedSchedule.startTime | momentOrdinalDate: selectedLocation?.ianaTimeZone}} <br>while you enter your name and contact information.
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="col-md-12 col-sm-12 col-xs-12 bottomspace">
                <mat-progress-bar mode="indeterminate" *ngIf="isWorking"></mat-progress-bar>
                <form [formGroup]="newPatientAppointment"
                      novalidate
                      (ngSubmit)="saveMarketingAppointment()">
                    <div class="section-container colored-bg">
                        <mat-form-field class="input-full-width">
                            <mat-label class="input-label">First Name</mat-label>
                            <input matInput
                                   type="text"
                                   formControlName="firstName"
                                   autocomplete="off">
                        </mat-form-field>

                        <mat-form-field class="input-full-width">
                            <mat-label class="input-label">Last Name</mat-label>
                            <input matInput
                                   type="text"
                                   formControlName="lastName"
                                   autocomplete="off">
                        </mat-form-field>

                        <mat-form-field class="input-full-width">
                            <mat-label class="input-label">Practice name</mat-label>
                            <input matInput
                                   type="text"
                                   formControlName="practiceName"
                                   autocomplete="off">
                        </mat-form-field>

                        <mat-form-field class="input-full-width">
                            <mat-label class="input-label">What email to send demo invite?</mat-label>
                            <input matInput
                                   type="text"
                                   formControlName="emailAddress"
                                   [textMask]="{mask: emailMask}"
                                   autocomplete="off">
                        </mat-form-field>

                        <mat-form-field class="input-full-width">
                            <mat-label class="input-label">list features that are most important to your practice</mat-label>
                            <textarea matInput
                                      type="text"
                                      formControlName="features"
                                      autocomplete="off">
                            </textarea>
                        </mat-form-field>

                        <button mat-button
                                [disabled]="!newPatientAppointment.valid || !this.selectedLocation || !selectedSchedule"
                                class="appintment-btn new-patient-url-button"
                                type="submit">
                            Please schedule my appointment
                        </button>
                    </div>
                </form>
            </div>

            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="section-container">
                  <div class="bottom-banner">
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>