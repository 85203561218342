import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';

import emailMask from 'text-mask-addons/dist/emailMask';
import { Router } from '@angular/router';
import { Store, ActionsSubject } from '@ngrx/store';
import { AuthStoreActions, AuthStoreSelectors, LocationsStoreActions, LocationsStoreSelectors, LocationStoreEntity, RootStoreState } from '../../../root-store';
import { CreateNewMarketingAppointmentDto, MarketingClient, NewPatientClient, PatientClient, RelationshipDto, RelationshipTypeEnum, ScheduleGridDto, ScheduleGridItemDto } from '../../../shared/services/api.service';
import { debounceTime, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AppointmentDetail } from '../../../shared/models/appointment/AppointmentDetail';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { fromEvent } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { MarketingDetail } from 'src/app/shared/models/appointment/MarketingDetails';

@Component({
    selector: 'marketing',
    templateUrl: './marketing.component.html',
    styleUrls: ['./marketing.component.css']
})
export class MarketingComponent implements OnInit, OnDestroy {
    recaptchaSiteKey: string = '6LcnU3EfAAAAAKeewez0hb84y23WHNK0PvYC_2EC';
    private _destroy$: Subject<boolean> = new Subject<boolean>();
    locations$ = this._store$.select(LocationsStoreSelectors.selectActiveLocations)
        .pipe(
            takeUntil(this._destroy$),
            map((locations) => {
                let location = locations[0];
                if (location)
                    this.selectLocation(location);

                return locations;
            })
        );
    locationIsLoading$ = this._store$.select(LocationsStoreSelectors.selectLocationsIsLoading);
    headerText$ = this._store$.select(AuthStoreSelectors.getHeaderText);
    footerText$ = this._store$.select(AuthStoreSelectors.getFooterText);
    logoLocationUrl$ = this._store$.select(AuthStoreSelectors.getLogoLocationUrl);

    emailMask = emailMask;
    phonemask: any = ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    selectedDate: Date;
    minDate: Date = new Date();
    maxDate: Date = null;
    readonly today = moment().toDate();

    blockIsLoading: boolean = false
    isWorking: boolean = false;

    selectedLocation: LocationStoreEntity;
    schedules: ScheduleGridItemDto[];
    selectedSchedule: ScheduleGridItemDto;
    newPatientAppointment: FormGroup;
    readonly relationshipTypes: any = _.toArray(RelationshipTypeEnum);
    @ViewChild("HearAboutInput", { static: false }) HearAboutInput: ElementRef;
    hearAboutUs: any[] = [
        {name: "Social media"},
        {name: "Friend"},
        {name: "Family"},
        {name: "Internet search"},
        {name: "Dental referral"},
        {name: "Other"}
    ];
    hearAboutUsFilter: any[] =[];

    locations: any;

    constructor(
        private _router: Router,
        private _store$: Store<RootStoreState.State>,
        private _marketingClient: MarketingClient,
        private _snackbar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private _patientClient: PatientClient,
        private reCaptchaV3Service: ReCaptchaV3Service,
        private _actions$: ActionsSubject
    ) { }

    ngOnDestroy() {
        this._destroy$.next(true);
    }

    ngOnInit(): void {
        this._store$.dispatch(LocationsStoreActions.LoadRequest({}));

        this._actions$
            .pipe(ofType(LocationsStoreActions.LoadSuccess), take(1), takeUntil(this._destroy$))
            .subscribe((result) => {
              console.log(result)
              this.locations = result.locations;

              let location = this.locations[0];
                if (location){
                    this.selectLocation(location);
                }
                    
          });


        this.initializeNewPatientAppointment();
    }

    ngAfterViewInit() {}

    reInitialize(): void {
        this.newPatientAppointment.reset();
        this.selectedSchedule = null;
        this.selectedLocation = null;
    }

    initalizeDateSelection(): void {
        this.onSelectDate(new Date());
    }

    navigateToMarketingDetail(){
        this._router.navigate(['/marketing-details']);
    }

    onSelectDate(event: Date) {
        if (!event || this.blockIsLoading) return null;

        this.blockIsLoading = true;
        this.schedules = null;
        this.selectedDate = new Date(event.toDateString());
        this.toggleDateSelection(false);
        this.selectedSchedule = null;

        this._marketingClient.marketing_GetAppointmentsAvailable(
            this.selectedLocation.id,
            this.selectedDate)
            .pipe(
                take(1),
                takeUntil(this._destroy$))
            .subscribe(
                (result) => {
                    let scheduleGrid: ScheduleGridDto = _.find(result, (r: ScheduleGridDto) => {
                        return r.date.getUTCDate() === this.selectedDate.getDate() &&
                            r.date.getUTCMonth() === this.selectedDate.getMonth() &&
                            r.date.getUTCFullYear() === this.selectedDate.getFullYear();
                    });
                    this.schedules = scheduleGrid ? scheduleGrid.items : null;
                    this.toggleDateSelection(true);
                    this.blockIsLoading = false;
                },
                (err) => {
                    this.blockIsLoading = false;
                    this.toggleDateSelection(true);
                    this._snackbar.open('An error has occured', 'OK', { duration: 3000 });
                }
            );
    }

    private toggleDateSelection(enable: boolean): void {
        if (enable)
            this.maxDate = null;
        else {
            this.maxDate = new Date();
            this.maxDate.setDate(this.minDate.getDate() - 1);
        }
    }

    myDateFilter = (d: Date): boolean => {
        const day = d.getDay();
        return day > -1;
    }

    selectLocation(location) {
        this.selectedLocation = location;
        this.initalizeDateSelection();
    }

    toggleSchedule(schedule: ScheduleGridItemDto): void {
        if (!schedule || this.blockIsLoading) return null;

        let toLock: boolean = !this.selectedSchedule || this.selectedSchedule.id != schedule.id;
        this.selectedSchedule = null;
        this.blockIsLoading = true;
        this.toggleDateSelection(false);

        if (toLock)
            this._marketingClient.marketing_PostBlockLock(
                this.selectedLocation.id,
                schedule.id)
                .pipe(
                    take(1),
                    takeUntil(this._destroy$))
                .subscribe(
                    () => {
                        this.selectedSchedule = schedule;
                        this.toggleDateSelection(true);
                        this.blockIsLoading = false;
                    },
                    (err) => {
                        this.selectedSchedule = null;
                        this.blockIsLoading = false;
                        this.toggleDateSelection(true);
                        this._snackbar.open(err.error, 'OK', { duration: 3000 });
                    }
                );
        else
            this._marketingClient.marketing_PostBlockUnlock(
                this.selectedLocation.id,
                schedule.id)
                .pipe(
                    take(1),
                    takeUntil(this._destroy$))
                .subscribe(
                    () => {
                        this.selectedSchedule = null;
                        this.toggleDateSelection(true);
                        this.blockIsLoading = false;
                    },
                    (err) => {
                        this.selectedSchedule = null;
                        this.blockIsLoading = false;
                        this.toggleDateSelection(true);
                        this._snackbar.open(err.error, 'OK', { duration: 3000 });
                    }
                );
    }

    initializeNewPatientAppointment(): void {
        this.newPatientAppointment = this._formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            practiceName: [null],
            emailAddress: [null],
            features: [''],
        });
    }

    

    saveMarketingAppointment(): void {
        if (!this.newPatientAppointment.valid) {
            this._snackbar.open('Please fill out requried fields.', 'OK', { duration: 3000 });
            return;
        }

        if (!this.selectedLocation) {
            this._snackbar.open('Please select location.', 'OK', { duration: 3000 });
            return;
        }        

        let newPatientAppointmentValue: any = this.newPatientAppointment.value;

        let createNewMarketingAppointmentDto: CreateNewMarketingAppointmentDto = new CreateNewMarketingAppointmentDto({
            procedureId: 0,
            patientStatusId: 0,
            patientFirstName: newPatientAppointmentValue.firstName,
            patientLastName: newPatientAppointmentValue.lastName,
            practiceName: newPatientAppointmentValue.practiceName,
            emailAddress: newPatientAppointmentValue.emailAddress,
            importantFeatures: newPatientAppointmentValue.features
        });

        this.toggleDateSelection(false);
        this.isWorking = true;

        let hangOnMessage = this._snackbar.open(
            "Hang on, I'm adding you to our schedule.",
            null,
            {
                horizontalPosition: 'end',
                verticalPosition: 'top'
            });

        let appointmentDetail: MarketingDetail = {
            patientId: null,
            patientFirstName: newPatientAppointmentValue.firstName,
            patientLastName: newPatientAppointmentValue.lastName,
            startTime: this.selectedSchedule ? this.selectedSchedule.startTime : null,
            locationName: this.selectedLocation.name,
            locationAddress: this.getLocationAddress(this.selectedLocation),
            locationTimeZone: this.selectedLocation.ianaTimeZone,
            patientContactId: null,
            patientRelationshipId: null,
            patientRelationshipEtag: null
        };

        sessionStorage.setItem("marketingdetail", JSON.stringify({appointmentDetail: appointmentDetail, location: this.selectedLocation}))

        this._store$.select(AuthStoreSelectors.getCommunicationDeskId)
            .pipe(
                take(1),
                takeUntil(this._destroy$)
            )
            .subscribe((communicationDeskId) => {
                createNewMarketingAppointmentDto.communicationDeskId = communicationDeskId;
                this.reCaptchaV3Service.execute(this.recaptchaSiteKey, 'homepage', (token) => {
                    createNewMarketingAppointmentDto.recaptchaToken = token;
                    this._marketingClient.marketing_PutBlock(
                        this.selectedLocation.id,
                        this.selectedSchedule ? this.selectedSchedule.id : 0,
                        createNewMarketingAppointmentDto)
                        .pipe(
                            switchMap(patient => {
                                appointmentDetail.patientId = patient.id;
                                return this._patientClient.patient_GetPatientRelationships(patient.id);
                            }),
                            take(1),
                            takeUntil(this._destroy$))
                        .subscribe(
                            (res: RelationshipDto[]) => {
                                let patientRelationship: RelationshipDto = _.find(res, { 'relationshipType': RelationshipTypeEnum.Patient });
                                if (patientRelationship) {
                                    appointmentDetail.patientContactId = patientRelationship.contactId;
                                    appointmentDetail.patientRelationshipId = patientRelationship.id;
                                    appointmentDetail.patientRelationshipEtag = patientRelationship.eTag;
                                }

                                this._store$.dispatch(AuthStoreActions.SetMarketingDetail({ marketingDetail: appointmentDetail }));
                                this._snackbar.open('New patient appointment successful.', 'OK', { duration: 3000 });
                                this.toggleDateSelection(true);
                                this.reInitialize();
                                hangOnMessage.dismiss();
                                this.isWorking = false;
                                this.navigateToMarketingDetail();
                            },
                            (err) => {
                                hangOnMessage.dismiss();
                                this.isWorking = false;
                                this.toggleDateSelection(true);
                                this._snackbar.open("An error has occured.", 'OK', { duration: 3000 });
                            }
                        );
                }, {
                    useGlobalDomain: false
                });
            });
    }

    private getLocationAddress(location: LocationStoreEntity): string {
        if (!location || !location.address) return null;

        let locationAddress = this.selectedLocation.address;
        return `${locationAddress.line1}, ${locationAddress.line2} ${locationAddress.city} ${locationAddress.state} ${locationAddress.zip}`;
    }
}
