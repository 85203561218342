import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthStoreSelectors, RootStoreState } from 'src/app/root-store';

@Component({
  templateUrl: './patient-portal.component.html',
  styleUrls: ['./patient-portal.component.scss']
})
export class PatientPortalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
