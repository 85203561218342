import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { AuthenticationService } from '../../shared/services/auth';
import { PortalTypeEnum } from '../../shared/services/api.service';
import { RootStoreState } from '../../root-store';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
    private _destroy$: Subject<boolean> = new Subject<boolean>();
    portalTypeEnum = PortalTypeEnum;
    portalType: PortalTypeEnum = PortalTypeEnum.Unknown;
    favIcon: HTMLLinkElement = document.querySelector('#appIcon');

    constructor(
        private _authService: AuthenticationService,
        private _store$: Store<RootStoreState.State>,
    ) {
        this.portalType = this._authService.getPortalType();

        console.log("portalType: ", this._authService.getMessageId());
        this.favIcon.href = 'favicon.ico'; 
        switch (this.portalType) {
            case this.portalTypeEnum.PatientCheckInUrl: {
                document.title = "Patient Portal - Check-In";
                break;
            }
            case this.portalTypeEnum.NewPatientUrl: {
                document.title = "Patient Portal - New Patient";
                break;
            }
            case this.portalTypeEnum.MarketingUrl: {
                document.title = "Patient Portal - Registration";
                break;
            }
            case this.portalTypeEnum.PatientSelfScheduleUrl: {
                document.title = "Patient Portal - Self-Schedule";
                break;
            }
            case this.portalTypeEnum.PatientRescheduleUrl: {
                document.title = "Patient Portal - Rechedule";
                break;
            }
            case this.portalTypeEnum.PatientCasePresentationUrl: {
                document.title = "Patient Portal - Case Presentation";
                break;
            }
            case this.portalTypeEnum.PatientConfirmUrl: {
                document.title = "Patient Portal - Confirm Appointment";
                break;
            }
            case this.portalTypeEnum.SecureMessageUrl: {
                document.title = "Patient Portal - Secure Email";
                break;
            }
            case this.portalTypeEnum.PatientFormUrl: {
                document.title = "Patient Portal - Forms";
                break;
            }
            case this.portalTypeEnum.PatientMarketingUrl: {
                document.title = "Patient Portal - Marketing";
                break;
            }
            case this.portalTypeEnum.PatientPaymentUrl: {
                document.title = "Patient Portal - Update Payment";
                break;
            }
            default: {
                document.title = "Patient Portal";
                break;
            }
        } 
    }

    ngOnDestroy(): void {
        this._destroy$.next(true);
    }

    ngOnInit(): void {
    }
}
