<div class="component-background">
    <mat-progress-bar mode="indeterminate" *ngIf="isWorking"></mat-progress-bar>
    <div class="header">
        <div style="display: flex; align-items: center; justify-content: space-between;">
            <div class="validity-info">This link is only valid for 7 days.</div>
            <button mat-icon-button (click)="generatePDF()">
                <fa-icon style="font-size: 20px;" [icon]="faFilePdf"></fa-icon>
            </button>
        </div>

        <div>
            <div class="attachment-label" *ngIf="securemsg?.attachments.length > 0">Attachments:</div>
            <ng-container *ngFor="let attachment of securemsg?.attachments">
                <button mat-button (click)="downloadAttachment(attachment)">
                    <fa-icon style="color: #FF5A5A" [icon]="faPaperclip"></fa-icon>
                    <span style="margin-left: 8px; font-weight: 400;">{{attachment.name}}</span>
                </button>
            </ng-container>
        </div>

        
        
    </div>
    <div class="inner-background" #content [ngStyle]="{'min-height': innerboxHt + 'px'}">
        <div class="email-elem">To: {{securemsg?.toRecipients[0]?.fullName || securemsg?.toRecipients[0]?.phoneOrEmail}}</div>
        <div class="email-elem" style="margin-bottom: 30px;">Subject: {{securemsg?.subject}}</div>
        <div class="email-elem" [innerHTML]="secureMsgHtml"></div>
    </div>
</div>