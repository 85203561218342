import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import {
	RootStoreState,
	AuthStoreSelectors,
	AppointmentStoreSelectors,
	AppointmentStoreActions,
	LocationsStoreSelectors,
	AppointmentStoreEntity
} from 'src/app/root-store';

import { map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/shared/services/auth';
import { LocationClient, AppointmentDto, PatientClient } from 'src/app/shared/services/api.service';
import { Observable, ReplaySubject, combineLatest, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'patient-check-in',
	templateUrl: './patient-check-in.component.html',
	styleUrls: ['./patient-check-in.component.scss']
})
export class PatientCheckInComponent implements OnInit, OnDestroy {
	private _destroy$: Subject<boolean> = new Subject<boolean>();
	monthArr: any[] = [
		{ id: 1, name: 'January' },
		{ id: 2, name: 'February' },
		{ id: 3, name: 'March' },
		{ id: 4, name: 'April' },
		{ id: 5, name: 'May' },
		{ id: 6, name: 'June' },
		{ id: 7, name: 'July' },
		{ id: 8, name: 'August' },
		{ id: 9, name: 'September' },
		{ id: 10, name: 'October' },
		{ id: 11, name: 'November' },
		{ id: 12, name: 'December' }
	];

	selectedDay: any;
	selectedMonth: any;
	selectedYear: any;
	currentYear: any;
	currentMonth: any;
	yearArr: any[] = [];
	daysArr: any[] = [];
	birthdaySubmitted: boolean = false;
	patientSelected: boolean = false;
	notShowing: boolean = false;
	selectedLocation$ = this._store$.select(LocationsStoreSelectors.getSelectedLocation);
	selectedLocationId: number;
	selectedLocationTimeZone: string;
	settings$ = this._store$.select(AuthStoreSelectors.getSettings);
	filteredAppointments$: Observable<AppointmentStoreEntity[]>;
	dob$: ReplaySubject<Date> = new ReplaySubject(1);
	checkInSubmitted:boolean = false;
	isWorking:boolean = false;

	constructor(
		private _store$: Store<RootStoreState.State>,
		private _authService: AuthenticationService,
		private patientClient: PatientClient,
		private _snackbar: MatSnackBar,
	) { }

	ngOnInit() {
		this.selectedLocation$
			.pipe(
				takeUntil(this._destroy$)
			)
			.subscribe(location => {
				this.selectedLocationId = location ? location.id : null;
				this.selectedLocationTimeZone = location ? location.ianaTimeZone : null;
		  });

		this.dob$
			.pipe(
				takeUntil(this._destroy$)
			)
			.subscribe((dob) => {
				this.filteredAppointments$ = this._store$.select(AppointmentStoreSelectors.selectAppointmentsByDob(dob))
			});

		this.currentYear = moment().get('year');
		this.currentMonth = moment().get('month');
		this.getDaysInMonth();
		this.getYears();
	}

	ngOnDestroy() {
		this._destroy$.next(true);
	}

	changeMonth(evnt) {
		this.getDaysInMonth();
		this.getPatientList();
	}

	getYears() {
		for (let i = 0; i <= 100; i++) {
			this.yearArr[i] = { name: this.currentYear - (100 - i) }
		}

		this.setDefaultYear();
	}

	setDefaultYear(){
		let defaultYear = this.currentYear - 10;
		this.selectedYear = this.yearArr.find(year => year.name == defaultYear);
	}

	changeYear(evnt) {
		this.getDaysInMonth();
		this.getPatientList();
	}

	getDaysInMonth() {
		this.daysArr = [];
		if (this.selectedMonth && this.selectedYear) {
			let daysInMonth = moment(`${this.selectedYear.name}-${this.selectedMonth.id}`, "YYYY-MM").daysInMonth();
			for (let i = 0; i < daysInMonth; i++) {
				this.daysArr[i] = { name: i + 1 }
			}

		} else {
			if (this.selectedMonth) {
				let daysInMonth = moment(`${this.currentYear}-${this.selectedMonth.id}`, "YYYY-MM").daysInMonth();
				for (let i = 0; i < daysInMonth; i++) {
					this.daysArr[i] = { name: i + 1 }
				}
			} else if (this.selectedYear) {
				let daysInMonth = moment(`${this.selectedYear.name}-${this.currentMonth + 1}`, "YYYY-MM").daysInMonth();
				for (let i = 0; i < daysInMonth; i++) {
					this.daysArr[i] = { name: i + 1 }
				}
			} else {
				let daysInMonth = moment().daysInMonth();
				for (let i = 0; i < daysInMonth; i++) {
					this.daysArr[i] = { name: i + 1 }
				}
			}
		}

		if (this.selectedDay) {
			let findDay = this.daysArr.find(day => day.name == this.selectedDay.name);
			if (!findDay) {
				this.selectedDay = null
			}
		}
	}

	changeDate(evnt) {
		this.getPatientList();
	}

	getPatientList() {
		if (this.selectedDay && this.selectedMonth && this.selectedYear) {
			let birthDate = moment(`${this.selectedMonth.id}/${this.selectedDay.name}/${this.selectedYear.name}`).format('MM/DD/YYYY');

			this.birthdaySubmitted = true;

			this.dob$.next(new Date(birthDate));
		}
	}

	selectPatient(patient:AppointmentDto) {
		if(!this.checkInSubmitted){
			this.checkInSubmitted = true;
			this.isWorking = true;

			this.patientClient.patient_PostAppointmentCheckIn(patient.patientId, patient.id, null, this._authService.getBrowserMaskedTenantKey()).subscribe(resp => {
				this.selectedDay = null;
				this.selectedMonth = null;
				this.setDefaultYear();
				this.birthdaySubmitted = false;
				this.patientSelected = true;
				this.checkInSubmitted = false;
				this.isWorking = false;
				setTimeout(() => {
					this.patientSelected = false;
				}, 15000)
			},
				err => {
					if (err && err.response) {
						let response = JSON.parse(err.response);
						if (response && response.message)
							this._snackbar.open(`We are unable to check you in at this time. ${response.message}`, 'OK');
					}
					this.checkInSubmitted = false;
					this.isWorking = false;
			})
		}
		
	}

	notShowingName() {
		this.selectedDay = null;
		this.selectedMonth = null;
		this.setDefaultYear();
		this.birthdaySubmitted = false;
		this.patientSelected = true;
		this.notShowing = true;

		setTimeout(() => {
			this.patientSelected = false;
			this.notShowing = false;
		}, 15000)
	}
}
