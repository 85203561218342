import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import * as _ from 'lodash';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateNewAppointmentDto, LocationBaseDto, LocationClient, ScheduleGridDto, ScheduleGridItemDto, PatientSelfScheduleUrlDto, PatientSelfScheduleClient, PatientClient, SelfSchedulingTxCardType  } from '../../../shared/services/api.service';
import { AuthenticationService } from '../../../shared/services/auth';
import { take, takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { Router } from '@angular/router';
import { AuthStoreSelectors, LocationStoreEntity, LocationsStoreSelectors, RootStoreState } from 'src/app/root-store';
import { Store } from '@ngrx/store';
import * as moment from 'moment';


@Component({
	selector: 'patient-schedule',
	templateUrl: './patient-schedule.component.html',
	styleUrls: ['./patient-schedule.component.css']
})
export class PatientScheduleComponent implements OnInit, OnDestroy {
	private _destroy$: Subject<boolean> = new Subject<boolean>();
	recaptchaSiteKey: string = '6LcnU3EfAAAAAKeewez0hb84y23WHNK0PvYC_2EC';
	today: any;
	dialogRef: MatDialogRef<any>;
	blockIsLoading: boolean = false
    isWorking: boolean = false;
	settings$ = this._store$.select(AuthStoreSelectors.getSettings);
	patientId: number;
	prescribedDate: Date;

    isEnabled: boolean = true;
	selectedDate: Date;
	minDate: Date = new Date();
    maxDate: Date = null;

	schedules: ScheduleGridItemDto[];
	selectedLocation;
	selectedSchedule: ScheduleGridItemDto;

	maskedTenantKey: string;
    patientSelfScheduleUrl: PatientSelfScheduleUrlDto;
    activeLocations$ = this._store$.select(LocationsStoreSelectors.selectActiveLocations);
    allActiveLocations;

    limitFromDate: any;
    limitToDate: any;
    patient: any;

    @ViewChild('confirmingDialog', { static: false }) confirmingDialog: TemplateRef<any>;

	constructor(
		public matDialog: MatDialog,
		public _locationClient: LocationClient,
		private _router: Router,
		private _snackbar: MatSnackBar,
		private _authService: AuthenticationService,
		private _reCaptcha: ReCaptchaV3Service,
		private _store$: Store<RootStoreState.State>,
        private patientSelfScheduleClient:PatientSelfScheduleClient,
	) { }

	ngOnDestroy() {
		this._destroy$.next(true);
	}

	ngOnInit(): void {
		this.isWorking = true;
		this.patientId = this._authService.getPatientId();
		this.maskedTenantKey = this._authService.getBrowserMaskedTenantKey();
        this.prescribedDate = this._authService.getPrescribedDate();

		this.isWorking = true;

        this.patientSelfScheduleClient.patientSelfSchedule_GetOptions().subscribe(resp => {
            this.isWorking = false;
            this.selectedLocation = resp.location;
            this.isEnabled = resp.settings.isEnabled;
            this.minDate = moment(resp.settings.limitFromDate).tz(resp.location.ianaTimeZone).toDate();
            this.maxDate = moment(resp.settings.limitToDate).tz(resp.location.ianaTimeZone).toDate();
            this.selectedDate = moment(resp.settings.limitFromDate).tz(resp.location.ianaTimeZone).toDate();
            this.patient = resp.patient;
            this.onSelectDate(this.selectedDate);

        },
        err => {
            this.isWorking = false;
            this._snackbar.open('An error has occured', 'OK', { duration: 3000 });
        })
                    
	}

	reInitialize(): void {
        this.selectedSchedule = null;
        this.selectedLocation = null;
    }

	initalizeDateSelection(): void {
        this.onSelectDate(new Date());
    }

	selectLocation(location) {
        this.selectedLocation = location;
        this.initalizeDateSelection();
    }

	myDateFilter = (d: Date): boolean => {
        const day = d.getDay();
        return day > -1;
    }

	onSelectDate(event: Date) {
        if (!event || this.blockIsLoading) return null;

        this.blockIsLoading = true;
        this.schedules = null;
        this.selectedDate = new Date(event.toDateString());
        ////this.toggleDateSelection(false);
        this.selectedSchedule = null;

        this._locationClient.location_GetAvailableBlocks(
			this.selectedLocation.id,
            this.selectedDate)
            .pipe(
                take(1),
                takeUntil(this._destroy$))
            .subscribe(
                (result) => {
                    let scheduleGrid: ScheduleGridDto = _.find(result, (r: ScheduleGridDto) => {
                        return r.date.getUTCDate() === this.selectedDate.getDate() &&
                            r.date.getUTCMonth() === this.selectedDate.getMonth() &&
                            r.date.getUTCFullYear() === this.selectedDate.getFullYear();
                    });
                    this.schedules = scheduleGrid ? scheduleGrid.items : null;
                    ////this.toggleDateSelection(true);
                    this.blockIsLoading = false;
                },
                (err) => {
                    this.blockIsLoading = false;
                    ////this.toggleDateSelection(true);
                    this._snackbar.open('An error has occured', 'OK', { duration: 3000 });
                }
            );
    }

    private toggleDateSelection(enable: boolean): void {
        if (enable)
            this.maxDate = null;
        else {
            this.maxDate = new Date();
            this.maxDate.setDate(this.minDate.getDate() - 1);
        }
    }

	toggleSchedule(schedule: ScheduleGridItemDto): void {
        if (!schedule || this.blockIsLoading) return null;

        let toLock: boolean = !this.selectedSchedule || this.selectedSchedule.id != schedule.id;
        this.selectedSchedule = null;
        this.blockIsLoading = true;
        //this.toggleDateSelection(false);

        if (toLock)
            this._locationClient.location_PostBlockLock(
                this.selectedLocation.id,
                schedule.id)
                .pipe(
                    take(1),
                    takeUntil(this._destroy$))
                .subscribe(
                    () => {
                        this.selectedSchedule = schedule;
                        //this.toggleDateSelection(true);
                        this.blockIsLoading = false;

                        this.dialogRef = this.matDialog.open(this.confirmingDialog);
                        this.dialogRef.afterClosed().subscribe(resp => {
                            if(resp){
                                this.saveAppointment();
                            }
                        })
                    },
                    (err) => {
                        this.selectedSchedule = null;
                        this.blockIsLoading = false;
                        //this.toggleDateSelection(true);
                        this._snackbar.open(err.error, 'OK', { duration: 3000 });
                    }
                );
        else
            this._locationClient.location_PostBlockUnlock(
                this.selectedLocation.id,
                schedule.id)
                .pipe(
                    take(1),
                    takeUntil(this._destroy$))
                .subscribe(
                    () => {
                        this.selectedSchedule = null;
                        //this.toggleDateSelection(true);
                        this.blockIsLoading = false;
                    },
                    (err) => {
                        this.selectedSchedule = null;
                        this.blockIsLoading = false;
                        //this.toggleDateSelection(true);
                        this._snackbar.open(err.error, 'OK', { duration: 3000 });
                    }
                );
    }

	saveAppointment(): void {
        if (!this.selectedLocation) {
            this._snackbar.open('Please select location.', 'OK', { duration: 3000 });
            return;
        }

        //this.toggleDateSelection(false);
        this.isWorking = true;

		let createNewAppointmentDto: CreateNewAppointmentDto = new CreateNewAppointmentDto({
            selfSchedulingTxCardType: SelfSchedulingTxCardType.Standard,
            patientId: this.patientId,
			locationId: this.selectedLocation.id,
			scheduleGridItemId: this.selectedSchedule.id,
			procedureId: 0
        });

        let hangOnMessage = this._snackbar.open(
            "Hang on, I'm adding you to our schedule.",
            null,
            {
                horizontalPosition: 'end',
                verticalPosition: 'top'
            });

        this._reCaptcha.execute(this.recaptchaSiteKey, 'homepage', (token) => {
			createNewAppointmentDto.recaptchaToken = token;
			this._locationClient.location_PutBlock(
				this.selectedLocation.id,
				this.selectedSchedule ? this.selectedSchedule.id : 0,
				createNewAppointmentDto)
				.subscribe(
					() => {
						this._snackbar.open('New appointment successful.', 'OK', { duration: 3000 });
						//this.toggleDateSelection(true);
						
						this.reInitialize();

						hangOnMessage.dismiss();
						this.isWorking = false;
						this.navigateToAppointmentDetail();
					},
					(err) => {
						hangOnMessage.dismiss();

						this.isWorking = false;
						////this.toggleDateSelection(true);
						this._snackbar.open("An error has occured.", 'OK', { duration: 3000 });
					}
				);
		}, {
			useGlobalDomain: false
		});
    }

	navigateToAppointmentDetail() {
        this._router.navigate(['/appointment-details']);
    }
}
