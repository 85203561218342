<div class="component-container" style="max-width: 768px; margin: auto;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="section-container" >
                    <div class="hold-information">
                        <div style="font-size: 24px; margin-bottom: 10px;">
                            {{marketingDetails?.appointmentDetail?.patientFirstName}} {{marketingDetails?.appointmentDetail?.patientLastName}}
                        </div>

                        <div>
                            <div>You're appointment is confirmed for</div>
                        </div>
                        
                        <div>
                            {{marketingDetails?.appointmentDetail?.startTime | date: 'MM/dd/yyyy' }}<br>
                            at<br>
                            {{marketingDetails?.appointmentDetail?.startTime | momentDate: 'hh:mm a' : marketingDetails?.appointmentDetail?.locationTimeZone  }}<br>
                            {{marketingDetails?.appointmentDetail?.locationName}}
                        </div>
                    </div>
                </div>

                <div class="section-container">
                    <div class="booking-info">
                        <ng-container *ngIf="(footerText$ | async) as footerText">
                            <div [innerHTML]="footerText"></div>
                        </ng-container>
                      </div>
                </div>

                <div class="section-container">
                    <div class="bottom-banner">
                        <ng-container *ngIf="(logoLocationUrl$ | async) as logoLocationUrl">
                          <img [src]="logoLocationUrl"
                               class="logo-container"
                               *ngIf="logoLocationUrl"/>
                        </ng-container>
                      </div>
                </div>

                <div style="margin-top: 10px; font-size: 14px; font-style:italic; text-align: center;">{{marketingDetails?.location?.emailAddress}}</div>
            </div>
        </div>
    </div>

</div>
