<div class="content-wrapper evolution-scrollbar">
    <div class="container content">
        <div class="col">
            <div class="inner-content mat-elevation-z6">
                <mat-progress-bar mode="indeterminate" *ngIf="!contractData || isGeneratingContract || isLoadingContent"></mat-progress-bar>
                <div class="contract-wrapper"
                     #contractContent
                     *ngIf="contractData && contractData.contract">
                    <ng-container *ngIf="contractData.contract.logo">
                        <div style="text-align: center; margin-bottom: 10px; cursor: pointer">
                            <img [src]="contractData.contract.logo"
                                 style="max-width: 120px; max-height: 120px;">
                        </div>
                    </ng-container>
                    <div class="practice-address">
                        <div class="address-line" *ngFor="let item of contractSectionHeaders"
                             [innerHTML]="item.body"></div>
                    </div>
                    <section>
                        <h2>Patient</h2>
                        <div class="patient-details">
                            <div class="name">
                                {{contractData.patientContact.firstName}} {{contractData.patientContact.lastName}}
                            </div>
                            <div class="birthdate">{{today | date: 'MM/dd/yyyy'}}</div>
                        </div>
                    </section>

                    <section *ngIf="contractData.signatureContact">
                        <h2>Responsible Party</h2>
                        <div class="responsible-details">
                            <div class="detail-line">
                                {{contractData.signatureContact.firstName}}
                                {{contractData.signatureContact.lastName}}
                            </div>
                            <ng-container *ngIf="contractData.signatureContact.address">
                                <div class="detail-line">{{contractData.signatureContact.address.addressLine1}}</div>
                                <div class="detail-line" *ngIf="contractData.signatureContact.address.addressLine2">
                                    {{contractData.signatureContact.address.addressLine2}}
                                </div>
                                <div class="detail-line">
                                    {{contractData.signatureContact.address.city}},
                                    {{contractData.signatureContact.address.state}} {{contractData.signatureContact.address.zip}}
                                </div>
                            </ng-container>
                        </div>
                    </section>

                    <section>
                        <h2>Contract</h2>

                        <p class="contract-paragraph"
                           [innerHTML]="getContractSectionBody(contractData.contract, ContractSectionTypeEnum.Body)">
                        </p>

                        <ng-container *ngFor="let contractSectionItem of contractSectionBodies">
                            <div class="contract-paragraph">
                                <span class="paragraph-header" [innerHTML]="contractSectionItem.heading"></span>
                                <p [innerHTML]="parseItemBody(contractSectionItem.body)"></p>
                            </div>

                            <div class="paragraph-signature-field"
                                 *ngIf="contractSectionItem.isSignature">
                                <img *ngIf="paragraphSignatures[contractSectionItem.id]"
                                     [src]="paragraphSignatures[contractSectionItem.id]">
                                <button mat-flat-button
                                        [color]="paragraphSignatures[contractSectionItem.id] == null ? 'warn' : null "
                                        (click)="openSignatureWindow('initials', contractSectionItem.id)"
                                        [disabled]="isLoadingContent"
                                        *ngIf="!isGeneratingContract"
                                        matTooltip="Sign">
                                    <mat-icon>history_edu</mat-icon>
                                    {{(paragraphSignatures[contractSectionItem.id] == null && 'Initials Required') || ''}}
                                </button>
                            </div>
                        </ng-container>

                        <div class="final-signature">
                            <div class="signature">
                                <img *ngIf="contractFinalSignature"
                                     [src]="contractFinalSignature">
                                <button mat-stroked-button [color]="contractFinalSignature == null ? 'warn' : 'primary' "
                                        (click)="openSignatureWindow('full')"
                                        [disabled]="isLoadingContent"
                                        *ngIf="!isGeneratingContract"
                                        matTooltip="Sign Now">
                                    <mat-icon>history_edu</mat-icon>
                                    SIGN NOW
                                </button>
                                <p class="by-line">
                                    (Signature of Guarantor)
                                </p>
                            </div>
                        </div>

                        <div *ngIf="!isGeneratingContract">
                            <button mat-raised-button
                                    color="primary"
                                    (click)="submitContract()"
                                    [disabled]="isLoadingContent">
                                SUBMIT
                            </button>
                        </div>
                    </section>
                </div>
                <mat-error *ngIf="errorMessage">{{errorMessage}}</mat-error>
            </div>
        </div>
    </div>
</div>

