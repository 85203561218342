import { Overlay, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, Inject, Injectable, NgModule, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMapTo, take, tap, withLatestFrom } from 'rxjs/operators';
import { AuthStoreActions, AuthStoreSelectors, RootStoreState } from 'src/app/root-store';
import { SignalRHubStoreActions, SignalRHubStoreSelectors } from 'src/app/root-store/signalr-hub-store';
import { API_BASE_URL, EntityEventHubMethodsEnum, EntityHubEvent, HubEventArea, ITokenDto, TokenDto } from '../api.service';

@UntilDestroy()
@Injectable()
export class EventHubService {
  private _tenantKey$ = this._store$.select(AuthStoreSelectors.selectTenantKey);
  private _currentUserIds$ = this._store$.select(AuthStoreSelectors.selectTokenIds);
  private _connection: signalR.HubConnection;
  private _events: Subject<EntityHubEvent> = new Subject<EntityHubEvent>();

  constructor(
    @Inject(API_BASE_URL) private _baseUrl: string,
    private _store$: Store<RootStoreState.State>,
    private _overlay: Overlay,
    private _positionBuilder: OverlayPositionBuilder,
    private _actions$: Actions
  ) {
    // Listen for certain events from Auth Store
    this._actions$
      .pipe(
        ofType(AuthStoreActions.AuthenticateSuccess, AuthStoreActions.RefreshSuccess),
        untilDestroyed(this),
        withLatestFrom(this._store$.select(AuthStoreSelectors.selectedUserTokenId), this._store$.select(AuthStoreSelectors.selectAllTokens))
      )
      .subscribe(([action, selectedUserTokenId, tokens]) => {
        if (action.type == AuthStoreActions.RefreshSuccess.type) {
          // Let hub know that new user has joined this client
          this._connection.send(EntityEventHubMethodsEnum.UserAuthEvent, action.id, action.userCredentials);
        }
      });
  }

  /**
   * Kickstart the hub connection to server
   */
  Start(viewContainerRef: ViewContainerRef = null) {
    if (viewContainerRef) this.createStatusOverlay(viewContainerRef);
    this._store$
      .select(AuthStoreSelectors.selectCredentials)
      .pipe(
        withLatestFrom(this._store$.select(AuthStoreSelectors.selectIsUserTokenExpired(Date.now()))),
        //wait until credentials are both present and not expired to start connection process
        filter(([creds, isExpired]) => creds && !isExpired),
        //distinct when user has changed or hub is disconnected (ex: User logs out [stops hub], same User logs back in [hub still stopped])
        distinctUntilChanged(
          ([aCreds], [bCreds]) => aCreds.id == bCreds.id && !(this._connection && this._connection.state == signalR.HubConnectionState.Disconnected)
        ),
        //convert to our tenant key
        switchMapTo(combineLatest([this._tenantKey$, this._currentUserIds$]))
      )
      .subscribe(([tenantKey, userIds]) => {
        //when switching users a current connection may be present
        if (this._connection && this._connection.state == signalR.HubConnectionState.Connected) {
          this.disconnect();
        }

        this.buildConnection(tenantKey);
        this.registerHandlers();
        this.connect()
          .catch((reason) => {
            console.log(reason);
          })
          .then(() => {
            //Lets server know of tenant so that it will start receiving tenant events
            this._connection.send(EntityEventHubMethodsEnum.SetTenant, tenantKey);
            //Subscribe to events for current logged users (for sync)
            this._connection.send(EntityEventHubMethodsEnum.UserLoggedIn, userIds);
            this._store$.dispatch(SignalRHubStoreActions.HubStarted());
          });
      });
  }

  /**
   * Creates a new overlay in the upper right corner indicated by a cloud dictating the current connection state of the service
   */
  private createStatusOverlay(viewContainerRef: ViewContainerRef) {
    const position = this._positionBuilder.global().right('0').top('0');
    const overlayRef = this._overlay.create({
      positionStrategy: position,
      width: '24px',
      height: '20px',
      hasBackdrop: false,
    });
    const portal = new ComponentPortal(HubServiceStatusComponent, viewContainerRef, viewContainerRef.injector);
    overlayRef.attach(portal);
  }

  /**
   * Returns a promise that will resolve to the current accessToken if present and not expired. Dispatches refresh when found token expired.
   * @param store - Store object must be passed in as `this` context is not available when signalR runs factory
   */
  private accessTokenFactory(store: Store<RootStoreState.State>): () => Promise<string> {
    return function () {
      return store
        .select(AuthStoreSelectors.selectCredentials)
        .pipe(
          withLatestFrom(store.select(AuthStoreSelectors.selectIsUserTokenExpired(Date.now()))),
          tap(([creds, isExpired]) => {
            //If our credentials are expired then dispatch a refresh request
            if (creds && isExpired)
              store.dispatch(
                AuthStoreActions.RefreshRequest({
                  id: creds.id,
                  accessToken: creds.accessToken,
                  refreshToken: creds.refreshToken
                })
              );
          }),
          //If credentials expired then do not continue
          filter(([creds, isExpired]) => creds && !isExpired),
          map(([creds]) => creds.accessToken),
          take(1)
        )
        .toPromise();
    };
  }

  /**
   * Set up connection to hub with auth token from store
   */
  private buildConnection(tenantKey: string) {
    this._connection = new signalR.HubConnectionBuilder()
      .withUrl(this._baseUrl + `/hubs/events?tenant_key=${tenantKey}`, {
        accessTokenFactory: this.accessTokenFactory(this._store$),
        headers: {
          'X-Tenant-Key': tenantKey,
          'Access-Control-Allow-Origin': '*',
        },
      })
      .withAutomaticReconnect()
      .build();
    this._connection.onreconnecting(() => {
      this._store$.dispatch(SignalRHubStoreActions.HubReconnecting());
    });
    this._connection.onreconnected(() => {
      //Set tenant after reconnect as signalR will have changed it connectionId
      this._connection.send(EntityEventHubMethodsEnum.SetTenant, tenantKey);
      //fetch current logged users and renable at hub for syncing
      this._currentUserIds$.pipe(take(1)).subscribe((userIds) => this._connection.send(EntityEventHubMethodsEnum.UserLoggedIn, userIds));
      this._store$.dispatch(SignalRHubStoreActions.HubReconnected());
    });
    this._connection.onclose((err) => {
      // Only display notification if not closed gracefully
      if (err) {
        console.log(err);
      }
      this._store$.dispatch(SignalRHubStoreActions.HubStopped());
    });
    this._store$.dispatch(SignalRHubStoreActions.HubCreated());
  }

  /**
   * Starts hub connection if it has been built
   */
  private connect() {
    if (this._connection) return this._connection.start();
    else throw 'Cannot connect to Hub with no built connection';
  }

  /**
   * Stops hub connection
   */
  private disconnect() {
    if (this._connection && this._connection.state == signalR.HubConnectionState.Connected) {
      this._connection.stop();
    }
  }

  /**
   * Set up handlers for events coming down from the server
   */
  private registerHandlers() {
    //Set up entity events
    this._connection.listen(EntityEventHubMethodsEnum.EntityEvent, (e) => {
      this._events.next(e);
      this._store$.dispatch(SignalRHubStoreActions.EntityEvent({ event: e }));
    });
  }

  /**
   * Get an observable listening for any events in the specified area and of specified enity type
   * @param area - Area to filter events to
   * @param type - Optional type of entity object you wish to reduce to
   * @returns An observable of `EntityHubEvents`
   */
  public GetEventListener(area: HubEventArea, type?: string): Observable<EntityHubEvent> {
    return this._events.pipe(
      //Filter to the area
      filter((eve) => eve.eventArea == area),
      //Optionally filter by an entitytype
      filter((eve) => (type != null ? eve.entityType == type : true))
    );
  }
}

/**
 * Extension method for using an enum as the method name since SignalR can't intuitively interpret a string enum
 */
declare global {
  namespace signalR {
    interface HubConnection {
      listen(methodEnum: EntityEventHubMethodsEnum, handler: (...args: any[]) => void): void;
    }
  }
}

signalR.HubConnection.prototype.listen = function (methodEnum: EntityEventHubMethodsEnum, handler: (...args: any[]) => void): void {
  let _self = this as signalR.HubConnection;
  let method: string = methodEnum;
  return _self.on(method, handler);
};

@UntilDestroy()
@Component({
  selector: 'hub-service-status',
  template: `<div>{{ icon }}</div>`,
  styleUrls: ['event-hub.scss'],
  host: {
    class: 'hub-service-status',
    '[class.connected]': 'connectionStatus == "connected"',
    '[class.connecting]': 'connectionStatus == "connecting"',
    '[class.disconnected]': 'connectionStatus == "disconnected"',
  },
  encapsulation: ViewEncapsulation.None,
})
export class HubServiceStatusComponent {
  connectionStatus: string;
  icon: string;

  constructor(private _store$: Store<RootStoreState.State>) {
    this._store$
      .select(SignalRHubStoreSelectors.selectHubConnectionStatus)
      .pipe(
        map((status) => {
          switch (status) {
            case signalR.HubConnectionState.Connected:
              return 'connected';
            case signalR.HubConnectionState.Disconnected:
            case signalR.HubConnectionState.Disconnecting:
              return 'disconnected';
            case signalR.HubConnectionState.Connecting:
            case signalR.HubConnectionState.Reconnecting:
              return 'connecting';
          }
        }),
        untilDestroyed(this)
      )
      .subscribe((status) => {
        switch (status) {
          case 'connected':
          case 'connecting':
            this.icon = 'cloud_queue';
            break;
          case 'disconnected':
            this.icon = 'cloud_off';
            break;
        }
        this.connectionStatus = status;
      });
  }
}

@NgModule({
  imports: [CommonModule],
  exports: [],
  declarations: [HubServiceStatusComponent],
  entryComponents: [HubServiceStatusComponent],
  providers: [EventHubService],
})
export class EventHubServiceModule {}
