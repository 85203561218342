<div class="component-container">
    <div class="location-name">
        <ng-container *ngIf="(settings$ | async) as settings">
            <div *ngIf="settings" [innerHTML]="settings.headerText"></div>
        </ng-container>
    </div>
    <div class="info-text" [ngStyle]="{'visibility': (patientSelected) ? 'hidden' : 'visible'}">Let us know you are here <br>by entering your date of birth.</div>
    <div class="birthday-selector-sectiion">
        <div class="date-box">
            <div class="date-box-label" (click)="month.open()">{{selectedMonth ? selectedMonth.name : 'month'}}</div>

            <mat-select class="custom-select" #month [(ngModel)]="selectedMonth" (selectionChange)="changeMonth($event)">
                <mat-option *ngFor="let month of monthArr" [value]="month" class="custom-select-option">{{month.name}}</mat-option>
            </mat-select>
        </div>    
        <div class="date-box">
            <div class="date-box-label" (click)="day.open()">{{selectedDay ? selectedDay.name : 'day'}}</div>

            <mat-select class="custom-select" #day [(ngModel)]="selectedDay" (selectionChange)="changeDate($event)">
                <mat-option *ngFor="let day of daysArr" [value]="day" class="custom-select-option">{{day.name}}</mat-option>
            </mat-select>
        </div>
        <div class="date-box">
            <div class="date-box-label" (click)="year.open()">{{selectedYear ? selectedYear.name : 'year'}}</div>
            <mat-select class="custom-select" #year [(ngModel)]="selectedYear" (selectionChange)="changeYear($event)">
                <mat-option *ngFor="let year of yearArr" [value]="year" class="custom-select-option">{{year.name}}</mat-option>
            </mat-select>
        </div>
    </div>

    <div style="width: 50%; margin: 0 auto;"><mat-progress-bar mode="indeterminate" *ngIf="isWorking"></mat-progress-bar></div>

    <div class="patient-list" *ngIf="birthdaySubmitted">
        <ng-container *ngFor="let patient of filteredAppointments$ | async, index as i;">
            <div class="patient-block"
                 *ngIf="!patient.isCheckedIn"
                 (click)="selectPatient(patient)">
                {{patient.patientFirstName}} {{patient.patientLastName}} - {{patient.startTime | date: 'h:mm a' : selectedLocationTimeZone}}
            </div>
        </ng-container>
        
        <!-- <div class="patient-block" (click)="selectPatient()">Sam B</div> -->

        <div class="notshowing" (click)="notShowingName()">my name is not showing up!</div>
    </div>

    <div class="patientSelectedMsg" *ngIf="patientSelected && !notShowing">
        You are checked in! <br>You will be called back shortly. <br><br>Can’t wait to see you!
    </div>

    <div class="notShowingMsg" *ngIf="notShowing">
        Please see receptionist to check in. <br>For some odd reason I don’t see you scheduled for today.
    </div>
</div>

