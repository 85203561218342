import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService { 
  private transactionCompleteSource = new Subject<any>();

  // Observable string streams
  transactionCompleteSource$ = this.transactionCompleteSource.asObservable();

  openSaleModal(token: string) {
    var emergePay = window["emergepay"];
    var that = this;
    
    emergePay.open({
      transactionToken: token,
      onTransactionSuccess: function(approvalData) {
        emergePay.close();

        that.transactionCompleteSource.next(approvalData);
      },
      // (optional) Callback function that gets called after a failure occurs during the transaction (such as a declined card)
      onTransactionFailure: function(failureData) {
        console.log("Failure Data", failureData);
      },
      // (optional) Callback function that gets called after a user clicks the close button on the modal
      onTransactionCancel: function () {
        console.log("transaction cancelled!");
      }
    });
  }
}
