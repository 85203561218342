import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigitalSignatureComponent } from '../digital-signature/digital-signature.component';

@Component({
  selector: 'app-signature-dialog',
  templateUrl: './signature-dialog.component.html',
  styleUrls: ['./signature-dialog.component.scss'],
})
export class SignatureDialogComponent implements OnInit {
  @ViewChild('signature', { static: true }) signatureElement: DigitalSignatureComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: SignatureDialogData, private _dialogRef: MatDialogRef<SignatureDialogComponent>) {}

  ngOnInit() {}

  done() {
    this.signatureElement.generateImage();
    this._dialogRef.close(<SignatureDialogResult>{ type: this.data.type, value: this.signatureElement.value });
  }
}

export type SignatureType = 'initials' | 'full';

export interface SignatureDialogData {
  type: SignatureType;
  currentValue?: string;
}

export interface SignatureDialogResult {
  type: SignatureType;
  value: string;
  paragraphId?: number;
}
