import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/services/auth/auth.guard';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: '', loadChildren: './dashboard/dashboard.module#DashboardModule', canActivate: [AuthGuard] },
    { path: 'appointment-details', loadChildren: './appointment-details/appointment-details.module#AppointmentDetailsModule' },
    { path: 'confirmation', loadChildren: './confirmation/confirmation.module#ConfirmationModule' },
    { path: 'marketing-details', loadChildren: './marketing-details/marketing-details.module#MarketingDetailsModule' },
    { path: 'patient', loadChildren: './patient-portal/patient-portal.module#PatientPortalModule' },
    { path: 'contracts', loadChildren: './contracts/contracts.module#ContractsModule' },
    { path: '**', loadChildren: './authenticator/authenticator.module#AuthenticatorModule' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthGuard],
})
export class AppRoutingModule { }
