import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { DigitalSignatureComponent } from './digital-signature.component';


@NgModule({
    imports: [
        MatButtonModule
    ],
    exports: [DigitalSignatureComponent],
    declarations: [DigitalSignatureComponent],
    providers: [],
})
export class DigitalSignatureModule { }
