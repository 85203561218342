import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppointmentDto, PatientClient } from '../../../shared/services/api.service';
import { AuthenticationService } from '../../../shared/services/auth';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

export enum AppointmentStatusEnum {
	Passed = "Passed",
	Canceled = "Canceled",
	Rescheduled = "Rescheduled",
	Confirmed = "Confirmed",
	Active = "Active",
	Unknown = "Unknown"
}

@Component({
	selector: 'patient-confirm',
	templateUrl: './patient-confirm.component.html',
	styleUrls: ['./patient-confirm.component.css']
})
export class PatientConfirmComponent implements OnInit, OnDestroy {
	@ViewChild('confirmingDialog', { static: false }) confirmingDialog: TemplateRef<any>;
	@ViewChild('cancelDialog', { static: false }) cancelDialog: TemplateRef<any>;
	private _destroy$: Subject<boolean> = new Subject<boolean>();
	today: any;
	dialogRef: MatDialogRef<any>;
	isWorking: boolean;
	patientId: number;
	appointmentId: number;
	maskedTenantKey: string;
	appointment: AppointmentDto;
	appointmentStatusEnum = AppointmentStatusEnum;
	appointmentStatus: AppointmentStatusEnum = AppointmentStatusEnum.Unknown;

	constructor(
		public matDialog: MatDialog,
		public patientClient: PatientClient,
		private _authService: AuthenticationService,
		private _snackbar: MatSnackBar,
	) { }

	ngOnDestroy() {
		this._destroy$.next(true);
	}

	ngOnInit(): void {
		this.isWorking = true;
		this.appointmentId = this._authService.getAppointmentId();
		this.patientId = this._authService.getPatientId();
		this.maskedTenantKey = this._authService.getBrowserMaskedTenantKey();

		this.patientClient.patient_GetAppointment(
			this.patientId,
			this.appointmentId,
			null,
			this.maskedTenantKey
		)
			.pipe(
				take(1),
				takeUntil(this._destroy$)
		)
			.subscribe((appointment: AppointmentDto) => {
				this.appointment = appointment;
				this.setAppointmentStatus();
				this.isWorking = false;
			},
			err => {
				this.isWorking = false;
				this._snackbar.open(err.message, 'OK', { duration: 3000 });
			});
	}

	setAppointmentStatus(): void {
		if (this.appointment.isConfirmed)
			this.appointmentStatus = AppointmentStatusEnum.Confirmed;
		else if (this.appointment.cancelledWhen)
			this.appointmentStatus = AppointmentStatusEnum.Canceled;
		else if (this.appointment.rescheduledWhen)
			this.appointmentStatus = AppointmentStatusEnum.Rescheduled;
		else if (this.appointment.startTime < moment().utc().toDate())
			this.appointmentStatus = AppointmentStatusEnum.Passed;
		else
			this.appointmentStatus = AppointmentStatusEnum.Active;
	}

	confirmAppointment() {
		this.isWorking = true;
		this.patientClient.patient_PostConfirmAppointment(
			this.patientId,
			this.appointmentId,
			null,
			this.maskedTenantKey
		)
			.pipe(
				take(1),
				takeUntil(this._destroy$)
			)
			.subscribe(() => {
				this.isWorking = false;
				this.appointmentStatus = AppointmentStatusEnum.Confirmed;
				this.dialogRef = this.matDialog.open(this.confirmingDialog);
			},
			err => {
				this.isWorking = false;
				this._snackbar.open(err.message, 'OK', { duration: 3000 });
			});
	}

	cancelAppointment() {
		this.dialogRef = this.matDialog.open(this.cancelDialog);
		this.dialogRef.afterClosed().subscribe(dialogResponse => {
			if (dialogResponse) {
				this.isWorking = true;
				this.patientClient.patient_PostCancelAppointment(
					this.patientId,
					this.appointmentId,
					null,
					this.maskedTenantKey
				)
					.pipe(
						take(1),
						takeUntil(this._destroy$)
					)
					.subscribe(() => {
						this.isWorking = false;
						this.appointmentStatus = AppointmentStatusEnum.Canceled;
					},
					err => {
						this.isWorking = false;
						this._snackbar.open(err.message, 'OK', { duration: 3000 });
					});
			}
		})
	}

}
