import { Component, ViewContainerRef } from '@angular/core';
import { environment } from '../environments/environment';
import { EventHubService } from './shared/services/event-hub/event-hub.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'NewPatientURL';
  constructor(eventHub: EventHubService, viewContainerRef: ViewContainerRef) {
    this.loadScript(environment.emergePay.assetsUrl);
    eventHub.Start(viewContainerRef);
  }

  public loadScript(url: string) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
