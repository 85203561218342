<div class="component-container" style="max-width: 768px; margin: auto;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12"></div>
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="section-container">
                    <div class="top-banner">
                        <img src="assets/dr-image.png">
                    </div>

                    <div class="detail-info-section">
                        <div class="patient-name">[patient name]</div>
                        <div class="info-text">You are scheduled</div>
                        <div class="schedule-info">Janurary 16 <br>at<br>10:30 am</div>
                        <div>124 SE Main Street<br>Vancouver, WA 98684</div>
                    </div>

                    <div class="final-msg">See you soon!
                        <br>bla bla bla
                        <br>bla bla bla
                        <br>some type of final message
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>