import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { State } from '../root-state';
import * as AppointmentStoreActions from './actions';
import * as AppointmentStoreSelectors from './selectors';
import { HubEventArea, LocationClient, PatientClient } from 'src/app/shared/services/api.service';
import { SignalRHubStoreActions } from '../signalr-hub-store';
import { LocationsStoreActions, LocationsStoreSelectors } from '../location-store';

@Injectable({ providedIn: 'root' })
export class AppointmentStoreEffects {
  private _selectedLocationId$ = this.store$.select(LocationsStoreSelectors.getSelectedLocationId);

  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private _locationClient: LocationClient,
    private _patientClient: PatientClient) { }

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(AppointmentStoreActions.LoadRequest),
    switchMap((action) => {
      return this._locationClient.location_GetTodaySchedules(action.locationId).pipe(
        map((result) => AppointmentStoreActions.LoadSuccess({ appointments: result })),
        catchError((err: HttpErrorResponse) => of(AppointmentStoreActions.LoadFailure({ error: err.message })))
      )
    })
  );

  @Effect()
  selectRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(AppointmentStoreActions.SelectRequest),
    switchMap((action) =>
      this.store$.select(AppointmentStoreSelectors.selectAppointmentById(action.id)).pipe(map((result) => AppointmentStoreActions.SelectSuccess({ appointment: result })))
    )
  );

  // Listen for updates from Locations
  locationSelectSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsStoreActions.SelectSuccess),
      withLatestFrom(this._selectedLocationId$),
      switchMap(([action, locationId]) => {
        if(!location) {
          console.log(location);
        }

        return this._locationClient.location_GetTodaySchedules(locationId).pipe(
          map((result) => AppointmentStoreActions.LoadSuccess({ appointments: result })),
          catchError((err: HttpErrorResponse) => of(AppointmentStoreActions.LoadFailure({ error: err.message })))
        )
      })
    )
  );

  // Listen for updates from hub
  scheduleEventsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalRHubStoreActions.EntityEvent),
      withLatestFrom(this._selectedLocationId$),
      filter(([action, locationId]) =>
          action.event.eventArea == HubEventArea.ScheduleEvent &&
          action.event.entityType == LocationClient.ScheduleGridOnDeckDto &&
          action.event.locationId == locationId
      ),
      map(([action, locationId]) =>
        AppointmentStoreActions.LoadRequest({ locationId: locationId })
      ),
    )
  );
}
