<mat-dialog-content>
    <digital-signature [size-ratio]="this.data.type == 'full' ? .25 : .75"
                       #signature>

    </digital-signature>
    <div>
        <button mat-button
                (click)="signature.clear()">
            Reset
        </button>
        <button mat-stroked-button
                (click)="done()">
            Done
        </button>
    </div>
</mat-dialog-content>
